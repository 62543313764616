import React from "react";
import statement2_left_img from "../Images/statement2_left_img.png";
import statement2_right_img from "../Images/statement2_right_img.png";
function Statement2() {
  let w = window.innerWidth;
  return (
    <div 
      style={{
        backgroundColor: "#121014",
        color: "#ffffff",
        fontFamily: "Inter",
      }}
    >
      <div className="container">
        <div className=" row ">
          {w > 992 ? (
            <div className="col-md-5">
              <div>
                <img 
                  src={statement2_left_img}
                  alt="left_img"
                  style={{
                    width: "100%",
                    backgroundSize: "cover",
                    // position: "relative",
                    // left: "-2%",
                  }}
                ></img>
              </div>
            </div>
          ) : (
            <div className="col-md-12" data-aos="fade-up" data-aos-duration="1000">
              <div>
                <div
                  className="mt-3"
                  style={{
                    color: "#FFFFFF",
                    fontSize: "0.8rem",
                    lineHeight: "18px",
                  }}
                >
                  My Challenge?
                </div>
                <div
                  className=""
                  style={{
                    fontSize: "3rem",
                    fontWeight: "700",
                    lineHeight: "56px",
                  }}
                >
                  <span style={{ color: "#9D4EDD" }}>High Cost</span>
                  <br />
                  <span>
                    {" "}
                    Recruitment
                    <br /> Practice
                  </span>
                </div>{" "}
                {w > 992 ? (
                  <div className="d-md-flex container statement2_points align-items-center">
                    <div className="ps-3 mt-3" >
                      <div data-aos="fade-up" data-aos-duration="1000"
                        style={{
                          background: "#1E1A20",
                          borderRadius: "8px",
                          width: "250px",
                          textAlign: "center",
                          border: "1px solid #FF7900",
                        }}
                        className="p-4"
                      >
                        Highly paid US Recruiters
                      </div>
                    </div>
                    <div className="ps-3 mt-3">
                      <div data-aos="fade-up" data-aos-duration="1000"
                        style={{
                          background: "#1E1A20",
                          borderRadius: "8px",
                          width: "230px",
                          textAlign: "center",
                          border: "1px solid #FF7900",
                        }}
                        className="p-4"
                      >
                        High Infra Cost
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="d-lg-flex flex-direction-row container  "
                   
                  >
                    <div className="ps-3 mt-3">
                      <div data-aos="fade-up" data-aos-duration="1000"
                        style={{
                          background: "#1E1A20",
                          borderRadius: "8px",
                          width: "250px",
                          textAlign: "center",
                          border: "1px solid #FF7900",
                        }}
                        className="p-4"
                      >
                        Highly paid US Recruiters
                      </div>
                    </div>
                    <div className="ps-3 mt-3">
                      <div data-aos="fade-up" data-aos-duration="1000"
                        style={{
                          background: "#1E1A20",
                          borderRadius: "8px",
                          width: "250px",
                          textAlign: "center",
                          border: "1px solid #FF7900",
                        }}
                        className="p-4"
                      >
                        High Infra Cost
                      </div>
                    </div>
                    <div className="ps-3 mt-3">
                      <div data-aos="fade-up" data-aos-duration="1000"
                        style={{
                          background: "#1E1A20",
                          borderRadius: "8px",
                          width: "250px",
                          textAlign: "center",
                          border: "1px solid #FF7900",
                        }}
                        className="p-4"
                      >
                        High Subscription Cost
                      </div>
                    </div>
                    <div align="center">
                      <div className="mt-5">
                        <img 
                          src={statement2_left_img}
                          alt="left_img"
                          align="center"
                          style={{
                            width: "100%",
                            backgroundSize: "cover",
                            // position: "relative",
                            // left: "-2%",
                          }}
                        ></img>
                      </div>
                    </div>
                  </div>
                )}
                {w > 992 ? (
                  <div data-aos="fade-up" data-aos-duration="1000"
                    style={{
                      background: "#1E1A20",
                      borderRadius: "8px",
                      width: "230px",
                      textAlign: "center",
                      marginLeft: "150px",
                      border: "1px solid #FF7900",
                    }}
                    className="p-4 mt-3"
                  >
                    High Subscription cost
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
    
          {w > 992 ? (
            <div className="col-md-4 py-5 mt-5"  data-aos="fade-up" data-aos-duration="1000">
              <div
                className="mt-5"
                style={{
                  color: "#FFFFFF",
                  fontSize: "0.8rem",
                  lineHeight: "18px",
                }}
              >
                My Challenge?
              </div>
              <div
                style={{
                  fontSize: "4.3rem",
                  fontWeight: "700",
                  lineHeight: "86px",
                }}
              >
                <span style={{ color: "#9D4EDD" }}>High Cost</span>
                <br />
                <span>
                  {" "}
                  Recruitment
                  <br /> Practice
                </span>
              </div>{" "}
              {w > 776 ? (
                <div className="d-md-flex flex-direction-row conatiner statement2_points align-items-center">
                  <div className="ps-3 mt-3">
                    <div data-aos="fade-up" data-aos-duration="1000"
                      style={{
                        background: "#1E1A20",
                        borderRadius: "8px",
                        width: "250px",
                        textAlign: "center",
                        border: "1px solid #FF7900",
                      }}
                      className="p-4"
                    >
                      Highly paid US Recruiters
                    </div>
                  </div>
                  <div className="ps-3 mt-3">
                    <div data-aos="fade-up" data-aos-duration="1000"
                      style={{
                        background: "#1E1A20",
                        borderRadius: "8px",
                        width: "230px",
                        textAlign: "center",
                        border: "1px solid #FF7900",
                      }}
                      className="p-4"
                    >
                      High Infra Cost
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="d-lg-flex flex-direction-row conatiner statement2_points align-items-center"
                  align="center"
                >
                  <div className="ps-3 mt-3">
                    <div data-aos="fade-up" data-aos-duration="1000"
                      style={{
                        background: "#1E1A20",
                        borderRadius: "8px",
                        width: "250px",
                        textAlign: "center",
                        border: "1px solid #FF7900",
                      }}
                      className="p-4"
                    >
                      Highly paid US Recruiters
                    </div>
                  </div>
                  <div className="ps-3 mt-3">
                    <div data-aos="fade-up" data-aos-duration="1000"
                      style={{
                        background: "#1E1A20",
                        borderRadius: "8px",
                        width: "250px",
                        textAlign: "center",
                        border: "1px solid #FF7900",
                      }}
                      className="p-4"
                    >
                      High Infra Cost
                    </div>
                  </div>
                </div>
              )}
              {w > 992 ? (
                <div data-aos="fade-up" data-aos-duration="1000"
                  style={{
                    background: "#1E1A20",
                    borderRadius: "8px",
                    width: "230px",
                    textAlign: "center",
                    marginLeft: "150px",
                    border: "1px solid #FF7900",
                  }}
                  className="p-4 mt-3"
                >
                  High Subscription cost
                </div>
              ) : (
                <div
                data-aos="fade-up" data-aos-duration="1000"
                  style={{
                    background: "#1E1A20",
                    borderRadius: "8px",
                    width: "250px",
                    textAlign: "center",
                    marginLeft: "27px",
                    border: "1px solid #FF7900",
                  }}
                  className="p-4 mt-3"
                >
                  High Subscription cost
                </div>
              )}
            </div>
          ) : (
            <div className="col-md-7 py-5 mt-5 px-5"></div>
          )}

          <br />

          {/* </div> */}

          {w > 992 ? (
            <div className="col-md-3">
              <div>
                <img 
                  src={statement2_right_img}
                  className="float-end"
                  alt="right_img"
                  style={{ width: "16%", position: "absolute", right: "0%" }}
                ></img>
              </div>
            </div>
          ) : (
            <div className="col-md-3">
              {/* <div>
                <img 
                src={statement2_right_img}
                className="float-end"
                alt="right_img"
                style={{ width: "100%", position:"relative", right:"0%" }}
              ></img>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Statement2;
