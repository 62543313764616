import React from "react";
import curve_img from "../Images/distruptors_curve_img.png";
function Disruptors() {
  let w = window.innerWidth;
    return (
    
    <div style={{ background: "#121014", color: "white", position:"relative" }}>
      <img
        className="mt-5 py-5"
        src={curve_img}
        style={{ position: "absolute", right: "0" }}
      ></img>{" "}
      <div className="container py-5 mt-5">
        { w > 996 ? <div>
          <span
            style={{
              position: "absolute",
              top: "-12%",
              color: "#9D4EDD",
              fontSize: "6.2rem",
              fontWeight: "800",
              lineHeight: "124px",
            }}
          >
            We Are
          </span>
          <br />
          <span
            style={{
              fontSize: "6.2rem",
              fontWeight: "800",
              lineHeight: "124px",
            }}
          >
            {" "}
            Disruptors!
          </span>
        </div> : <div>
          <span
            style={{
                position: "absolute",
                top: "-10%",
              color: "#9D4EDD",
              fontSize: "4rem",
              fontWeight: "800",
              lineHeight: "100px",
            }}
          >
            We Are
          </span>
          <br />
          <span
            style={{
              fontSize: "3.6rem",
              fontWeight: "800",
              lineHeight: "40px",
            }}
          >
            {" "}
            Disruptors!
          </span>
        </div>}
        {" "}
      </div>
      <div className="  container">
        <div className="row py-5 ">
          <div className="col-md-5  py-3 " >
            {" "}
            <div className="d-flex ">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1rem", fontWeight: "600",  }}
              >
                Indepth knowledge and experience with Technical Skiils
              </div>
            </div>
            <div className="d-flex mt-5">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1rem", fontWeight: "600" }}
              >
                Our Uniq Recruitment Process/Strategy (SAMI)
              </div>
            </div>
            <div className="d-flex mt-5">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1rem", fontWeight: "600" }}
              >
                We are passionate about Recruitment
              </div>
            </div>
          </div>

          <div className="col-md-5  py-3" >
            {" "}
            <div className="d-flex ">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1rem", fontWeight: "600" }}
              >
                Customer Centric
              </div>
            </div>
            <div className="d-flex mt-5">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1rem", fontWeight: "600" }}
              >
                Excellent Communication Skills
              </div>
            </div>
            <div className="d-flex mt-5">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1rem", fontWeight: "600" }}
              >
                Domain Knowledge/Expertise
              </div>
            </div>
          </div>
          <div className="col-md-2 "></div>
        </div>
      </div>
    </div>
  );
}
export default Disruptors;
