import React from "react";
import statement5 from "../Images/statement5.png";
function Statement5() {
  let w = window.innerWidth;
  return (
    <div
      style={{ background: "#121014", fontFamily: "Inter" }}
      className="py-5"
    >
      <div
        className="py-5 container text-white"
        style={{ fontFamily: "Inter" }}
      >
        <div className="row ">
          {w > 992 ? (
            <div
              className="py-5 col-md-5 mt-5 "
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="" style={{ fontSize: "0.8rem" }}>
                My Challenge?
              </div>
              <div className="d-flex">
                <div
                  className=""
                  style={{
                    fontSize: "4.2rem",
                    fontWeight: "700",
                    lineHeight: "120%",
                  }}
                >
                  <div>
                    <span style={{ color: "#9D4EDD" }}>
                      {" "}
                      Ineffective monitoring{" "}
                    </span>
                    WFH / Remote Recruiters
                  </div>
                </div>{" "}
              </div>
            </div>
          ) : (
            <div
              className="col-md-9"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="" style={{ fontSize: "0.8rem" }}>
                My Challenge?
              </div>
              <div className="d-flex">
                <div
                  className=""
                  style={{
                    fontSize: "3.3rem",
                    fontWeight: "700",
                    lineHeight: "56px",
                  }}
                >
                  <div>
                    <span style={{ color: "#9D4EDD" }}>
                      {" "}
                      Ineffective monitoring{" "}
                    </span>
                    WFH / Remote Recruiters
                  </div>
                </div>
              </div>
              <br />
            </div>
          )}

          {/* {w > 992 ? (
            <div className="col-md-2 mt-5">
              <div className="mt-5  py-5">
                <div
                  style={{
                    background: "#1E1A20",
                    borderRadius: "8px",
                    width: "350px",
                    textAlign: "left",
                  }}
                  className="mt-5 p-5 "
                >
                  Ineffective remote monitoring software
                </div>
              </div>
              <div
                style={{
                  background: "#1E1A20",
                  borderRadius: "8px",
                  width: "300px",
                  textAlign: "center",
                }}
              >
                <div className="py-5 ">Moonlighting</div>
              </div>
            </div>
          ) : (
            ""
          )} */}
          {w > 992 ? (
            <div className="col-md-3 mt-5 p-5 ">
              <div className="mt-5 " data-aos="fade-up" data-aos-duration="1000">
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  style={{
                    background: "#1E1A20",
                    borderRadius: "8px",
                    width: "300px",
                    // textAlign: "center",
                    border: "1px solid #FF7900",
                    // right: "-10%",
                    position: "relative",
                    fontSize: "0.9rem",
                  }}
                  className="p-4  mt-3 "
                >
                  Ineffective remote monitoring software
                </div>
              </div>
              <div className="ps-3" data-aos="fade-up" data-aos-duration="1000">
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  style={{
                    background: "#1E1A20",
                    borderRadius: "8px",
                    width: "300px",
                    textAlign: "center",
                    border: "1px solid #FF7900",
                    right: "-20%",
                    position: "relative",
                  }}
                  className="p-4 mt-3 "
                >
                  Moonlighting
                </div>
              </div>

              <div className="ps-4" data-aos="fade-up" data-aos-duration="1000">
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  style={{
                    background: "#1E1A20",
                    borderRadius: "8px",
                    width: "300px",
                    textAlign: "center",
                    border: "1px solid #FF7900",
                    right: "-40%",
                    position: "relative",
                  }}
                  className="p-4 mt-3 "
                >
                  lack of productivity
                </div>
              </div>
              <div className="ps-5" data-aos="fade-up" data-aos-duration="1000">
                <div
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  style={{
                    background: "#1E1A20",
                    borderRadius: "8px",
                    width: "300px",
                    textAlign: "center",
                    border: "1px solid #FF7900",
                    right: "-60%",
                    position: "relative",
                  }}
                  className="p-4 mt-3 "
                >
                  Personal engagement
                </div>
              </div>
            </div>
          ) : (
            <div className="col-md-12  ">
              <div className="mt-5 " data-aos="fade-up" data-aos-duration="1000">
                <div
                  style={{
                    background: "#1E1A20",
                    borderRadius: "8px",
                    width: "300px",
                    // textAlign: "center",
                    border: "1px solid #FF7900",
                    // right: "-10%",
                    // position: "relative",
                    fontSize: "0.9rem",
                  }}
                  className="p-4  mt-3 "
                >
                  Ineffective remote monitoring software
                </div>
              </div>
              <div className="">
                <div data-aos="fade-up" data-aos-duration="1000"
                  style={{
                    background: "#1E1A20",
                    borderRadius: "8px",
                    width: "300px",
                    textAlign: "center",
                    border: "1px solid #FF7900",
                    // right: "-10%",
                    // position: "relative",
                  }}
                  className="p-4 mt-3 "
                >
                  Moonlighting
                </div>
              </div>

              <div className="">
                <div data-aos="fade-up" data-aos-duration="1000"
                  style={{
                    background: "#1E1A20",
                    borderRadius: "8px",
                    width: "300px",
                    textAlign: "center",
                    border: "1px solid #FF7900",
                    // right: "-10%",
                    // position: "relative",
                  }}
                  className="p-4 mt-3 "
                >
                  lack of productivity
                </div>
              </div>
              <div className="">
                <div data-aos="fade-up" data-aos-duration="1000"
                  style={{
                    background: "#1E1A20",
                    borderRadius: "8px",
                    width: "300px",
                    textAlign: "center",
                    border: "1px solid #FF7900",
                    // right: "-10%",
                    // position: "relative",
                  }}
                  className="p-4 mt-3 "
                >
                  Personal engagement
                </div>
              </div>
            </div>
          )}

          {w > 992 ? (
            <div className="col-md-4">
              <div>
                <img
                  className="float-end"
                  src={statement5}
                  alt="inefficient"
                  style={{ width: "100%" }}
                ></img>
              </div>
            </div>
          ) : (
            <div className="justify-content-center">
              <div align="center">
                <img
                  className="mt-5"
                  src={statement5}
                  alt="inefficient"
                  style={{ width: "80%", height: "60%" }}
                ></img>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Statement5;
