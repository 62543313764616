import React from "react";
import left_curve from "../Images/left_curve.png";
function Tools() {
  return (
    <div style={{ background: "#1E1A20", color: "white" }}>
      <div className="container py-5 mt-5">
        <div style={{ position: "absolute", left: "0" }}>
          <img src={left_curve}></img>
        </div>
        <div style={{ fontSize: "46px", fontWeight: "800" }} align="center">
          Infrastructure & Tools
        </div>
        <div className="row mt-5 py-5" >
          <div className="col-md-6" >
            {" "}
            <div className="d-flex mt-5" >
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1.2rem", fontWeight: "700", lineHeight:"24px" }}
              >
                State of the art facility centers
              </div>
            </div>
            <div className="d-flex mt-5">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1.2rem", fontWeight: "700", lineHeight:"24px" }}
              >
                24x7 access
              </div>
            </div>
            <div className="d-flex mt-5 ">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1.2rem", fontWeight: "700", lineHeight:"24px" }}
              >
                Video Camera Monitoring System
              </div>
            </div>
            <div className="d-flex mt-5 ">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1.2rem", fontWeight: "700", lineHeight:"24px" }}
              >
                VOIP Telephone System
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-5">
            {" "}
            <div className="d-flex  ">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1.2rem", fontWeight: "700", lineHeight:"24px" }}
              >
                Remote Access Software
              </div>
            </div>
            <div className="d-flex mt-5 ">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1.2rem", fontWeight: "700", lineHeight:"24px" }}
              >
                High Speed Internet Access
              </div>
            </div>
            <div className="d-flex mt-5 ">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1.2rem", fontWeight: "700", lineHeight:"24px" }}
              >
                Productivity Software
              </div>
            </div>
            <div className="d-flex mt-5 ">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div
                className="ps-2"
                style={{ fontSize: "1.2rem", fontWeight: "700", lineHeight:"24px" }}
              >
                Access to various job portals / ATS etc
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Tools;
