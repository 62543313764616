import React from "react";
import Navbar from "./Navbar";
import le_left_img from "../Images/le_left_img.png";
import le_right_img from "../Images/le_right_img.png";
import CourseFeatures from "./CourseFeatures";
import ChooseUs from "./ChooseUs";
import Faq from "./Faq";
import Testimonials from "./Testimonials";
import Career from "./Carrer";
import WebsiteFooter from "./WebsiteFooter";
function LearnAndEarn() {
  return (
    <div>
      <div>
        <Navbar />
        <div className="mt-5 container">
          <div className="mt-5 row conatiner">
            <div className="col-md-3 mt-5">
              <img src={le_left_img} style={{ width: "350px" }} alt="left_img"></img>
            </div>
            <div className="col-md-6 mt-5 " align="center">
              <div style={{ fontSize: "3rem", fontWeight: "700" }}>
                Learn & Earn with talentRPO{" "}
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "600",
                  lineHeight: "32px",
                  color: "#444146",
                }}
              >
                We don’t compromise on the quality, we emphasize them! <br />
                Talent RPO - The best recruiters you are looking for.
              </div>
              <div className="mt-3">
                <input
                  type="text"
                  className="ps-5"
                  style={{
                    backgroundColor: "black",
                    color: "#444146",
                    borderRadius: "32px",
                    height: "50px",
                    width: "440px",
                    border: "none",
                  }}
                  placeholder="Enter your email Id"
                ></input>
              </div>
            </div>
 
            <div className="col-md-3 mt-5">
              <img src={le_right_img} style={{ width: "350px" }} alt="right_img"></img>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "black" }}>
          <div className=" py-5 container">
            <div
              className="text-white"
              align="center"
              style={{ fontSize: "3.3rem", fontWeight: "800" }}
            >
              How it Works?
            </div>
            <div className="row container mt-5">
              <div className="col-md-4">
                <div className="p-3">

                
                <div
                  style={{
                    fontSize: "46px",
                    fontWeight: "800",
                    color: "#7D7A7E",
                  }}
                >
                  1
                </div>
                <div
                  className="text-white"
                  style={{
                    fontWeight: "600",
                    fontSize: "1.4rem",
                    lineHeight: "32px",
                  }}
                >
                  Lorem ipsum dolor
                </div>
                <div
                  className="text-white"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "24px",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div></div>
              </div>
              <div className="col-md-4">
                <div className="p-3">
                <div
                  style={{
                    fontSize: "46px",
                    fontWeight: "800",
                    color: "#7D7A7E",
                  }}
                >
                  2
                </div>
                <div
                  className="text-white"
                  style={{
                    fontWeight: "600",
                    fontSize: "1.4rem",
                    lineHeight: "32px",
                  }}
                >
                  Lorem ipsum dolor
                </div>{" "}
                <div
                  className="text-white"
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "24px",
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div></div>{" "}
              </div>
              <div className="col-md-4">
                <div className="p-3">
                  <div
                    style={{
                      fontSize: "46px",
                      fontWeight: "800",
                      color: "#7D7A7E",
                    }}
                  >
                    3
                  </div>{" "}
                  <div
                    className="text-white"
                    style={{
                      fontWeight: "600",
                      fontSize: "1.4rem",
                      lineHeight: "32px",
                    }}
                  >
                    Lorem ipsum dolor
                  </div>{" "}
                  <div
                    className="text-white"
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                  >
                    Lorem ipsum dolor sit amet, consectetur adip iscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <CourseFeatures />
        </div>
        <div>
          <ChooseUs />
        </div>
        <div>
          <Testimonials /> 
        </div>
        <div style={{background:"black", color:"white"}}>
         <Faq className="text-white" color={"bg-dark text-white"}/>
        </div>
        <div>
          <Career />
        </div>
        <div>
          <WebsiteFooter />
        </div>
      </div>
    </div>
  );
}
export default LearnAndEarn;
