import React from "react";
import Heroimage from "../Images/hero_side_image.png";
import Navbar from "./Navbar";
import "../Styles/HeroSection.css";
import thunder_icon from "../Images/thunder_icon.svg";
import { useEffect } from "react";
import hero_arrow from "../Images/hero_section_arrow.png";
import hero_tick_img from "../Images/hero_tick.svg";
import three_dots from "../Images/three_dots.png";
import mobile_hero_image from "../Images/mobile_hero_image.svg";
export default function Herosection() {
  useEffect(() => {
    localStorage.setItem("landing_page", 1);
  }, []);

  let w = window.innerWidth;
  return (
    <div>
      <div className="mt-5 p-5 mobile_hero_responisve" id="home">
        <Navbar />
        <div className="container ">
          <div className="row  hero_mobile">
            <div className="col-lg-5 mt-5 ">
              <div
                className="py-2 hero_section_text"
                style={{
                  fontSize: "2.9rem",
                  fontWeight: "800",
                  fontStyle: "normal",
                  lineHeight: "68px",
                  width: "100%",
                  color: "#151216",
                }}
              >
                We Are Your Extended & Trusted <br />
                <span style={{ color: "#9D4EDD" }}>
                  IT Rec
                  <span
                    style={{
                      background: "radial-gradient(#FF7900, #9D4EDD)",
                      backgroundClip: "text",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    ruitment Team
                  </span>
                </span>
              </div>

              <div className=" py-2 trial_button w-100">
                <div className="py-2">
                  {" "}
                  <a
                    href="#reach-out"
                    className="text-decoration-none text-center text-white"
                  >
                    <div
                      className="btn  w-100 py-3 px-5 text-white get_fifteen_mobile"
                      style={{
                        backgroundColor: "#9D4EDD",
                        width: "5rem",
                        whiteSpace: "nowrap",
                        lineHeight: "1.25rem",

                        fontSize: "1rem",
                        fontWeight: "700",
                      }}
                    >
                      Get Free Trial
                    </div>{" "}
                  </a>
                </div>
                {/* <div className="py-2">
                  {" "}
                  <a
                    href="tel:+732 534 3870"
                    className=" p-3 text-decoration-none "
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      lineHeight: "24px",
                      color: "#9D4EDD",
                    }}
                  >
                    <div
                      className="ms-2 btn py-3 px-5 "
                      style={{
                        border: "2px solid #9D4EDD",
                        color: "#9D4EDD",
                        // width: "220px",
                        // height: "56px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <span className="mb-5">
                        <img src={thunder_icon} alt="thunder_icon"></img>
                      </span>{" "}
                      Quick Call
                    </div>
                  </a>
                </div> */}
              </div>
            </div>
            {w > 996 ? (
              <div className="col-lg-1 mt-5 py-5">
                <img src={hero_arrow}></img>
              </div>
            ) : (
              ""
            )}

            {w > 800 ? (
              <div className="col-lg-6  hero_image">
                <div align="left" style={{ position: "absolute" }}>
                  <img src={three_dots}></img>
                  <div className="px-4">
                    <div className="mt-3">
                      <span>
                        <img src={hero_tick_img}></img>
                      </span>
                      <span
                        className="ps-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          color: "#18181B",
                        }}
                      >
                        {" "}
                        20+ Years of Industry Experience
                      </span>
                    </div>
                    <div className="mt-3">
                      <span>
                        <img src={hero_tick_img}></img>
                      </span>
                      <span
                        className="ps-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          color: "#18181B",
                        }}
                      >
                        {" "}
                        Highly Qualified IT Recruiters
                      </span>
                    </div>
                    <div className="mt-3">
                      <span>
                        <img src={hero_tick_img}></img>
                      </span>
                      <span
                        className="ps-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          color: "#18181B",
                        }}
                      >
                        {" "}
                        Guranteed Result
                      </span>
                    </div>
                    <div className="mt-3">
                      <span>
                        <img src={hero_tick_img}></img>
                      </span>
                      <span
                        className="ps-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          color: "#18181B",
                        }}
                      >
                        {" "}
                        ZERO CAPEX
                      </span>
                    </div>
                    <div className="mt-3">
                      <span>
                        <img src={hero_tick_img}></img>
                      </span>
                      <span
                        className="ps-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          color: "#18181B",
                        }}
                      >
                        {" "}
                        Performance Tracking Tool
                      </span>
                    </div>
                    <div className="mt-3">
                      <span>
                        <img src={hero_tick_img}></img>
                      </span>
                      <span
                        className="ps-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          color: "#18181B",
                        }}
                      >
                        {" "}
                        Remote Monitoring
                      </span>
                    </div>

                    <div className="mt-3">
                      <span>
                        <img src={hero_tick_img}></img>
                      </span>
                      <span
                        className="ps-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          color: "#18181B",
                        }}
                      >
                        {" "}
                        At least 50% Reduction In Cost
                      </span>
                    </div>
                    <div className="mt-3">
                      <span>
                        <img src={hero_tick_img}></img>
                      </span>
                      <span
                        className="ps-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          color: "#18181B",
                        }}
                      >
                        {" "}
                        No Long Term Committment
                      </span>
                    </div>
                    <div className="mt-3">
                      <span>
                        <img src={hero_tick_img}></img>
                      </span>
                      <span
                        className="ps-2"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          lineHeight: "24px",
                          color: "#18181B",
                        }}
                      >
                        {" "}
                        TRY FOR FREE
                      </span>
                    </div>
                  </div>
                </div>
                <img
                  alt="hero_img"
                  src={Heroimage}
                  className="hero_img"
                  align="right"
                />
              </div>
            ) : (
              // <div className="col-lg-6 mt-4 hero_image" align="center">
              //   <img
              //     alt="hero_img"
              //     src={Heroimage}
              //     style={{ width: "60%" }}
              //     className="hero_img"
              //   />
              // </div>
              <div className="  hero_image mt-5 py-5 px-2">
                <div
                  className="mt-5 px-5 py-2 d-flex justify-content-center"
                  style={{ position: "absolute", top: "5%" }}
                  align="left"
                >
                  <div
                    className="mt-5  p-3 ps-4 py-5"
                    style={{
                      background: "linear-gradient(#FFFFFF00, #FFFFFF)",
                    }}
                  >
                    <img src={three_dots}></img>
                    <div>
                      {" "}
                      <div className="mt-2">
                        <span>
                          <img src={hero_tick_img}></img>
                        </span>
                        <span
                          className="ps-2"
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",

                            color: "#18181B",
                          }}
                        >
                          {" "}
                          20+ Years of Industry Experience
                        </span>
                      </div>
                      {/* <div>yuvasri</div> */}
                      <div className="">
                        <span>
                          <img src={hero_tick_img}></img>
                        </span>
                        <span
                          className="ps-2"
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",
                            lineHeight: "-10px",
                            color: "#18181B",
                          }}
                        >
                          {" "}
                          Highly Qualified IT Recruiters
                        </span>
                      </div>
                      <div>
                        <span>
                          <img src={hero_tick_img}></img>
                        </span>
                        <span
                          className="ps-2"
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",
                            lineHeight: "-10px",
                            color: "#18181B",
                          }}
                        >
                          {" "}
                          Guranteed Result
                        </span>
                      </div>
                      <div>
                        <span>
                          <img src={hero_tick_img}></img>
                        </span>
                        <span
                          className="ps-2"
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",
                            lineHeight: "-10px",
                            color: "#18181B",
                          }}
                        >
                          {" "}
                          ZERO CAPEX
                        </span>
                      </div>
                      <div>
                        <span>
                          <img src={hero_tick_img}></img>
                        </span>
                        <span
                          className="ps-2"
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",
                            lineHeight: "-10px",
                            color: "#18181B",
                          }}
                        >
                          {" "}
                          Performance Tracking Tool
                        </span>
                      </div>
                      <div>
                        <span>
                          <img src={hero_tick_img}></img>
                        </span>
                        <span
                          className="ps-2"
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",
                            lineHeight: "-10px",
                            color: "#18181B",
                          }}
                        >
                          {" "}
                          Remote Monitoring
                        </span>
                      </div>
                      <div>
                        <span>
                          <img src={hero_tick_img}></img>
                        </span>
                        <span
                          className="ps-2"
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",
                            lineHeight: "-10px",
                            color: "#18181B",
                          }}
                        >
                          {" "}
                          At least 50% Reduction In Cost
                        </span>
                      </div>
                      <div>
                        <span>
                          <img src={hero_tick_img}></img>
                        </span>
                        <span
                          className="ps-2"
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",
                            lineHeight: "-10px",
                            color: "#18181B",
                          }}
                        >
                          {" "}
                          No Long Term Committment
                        </span>
                      </div>
                      <div>
                        <span>
                          <img src={hero_tick_img}></img>
                        </span>
                        <span
                          className="ps-2 "
                          style={{
                            fontSize: "11px",
                            fontWeight: "400",
                            lineHeight: "-10px",
                            color: "#18181B",
                          }}
                        >
                          {" "}
                          TRY FOR FREE
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="float-right mt-5">
                  <img
                    alt="hero_img"
                    align="center"
                    src={mobile_hero_image}
                    width="100%"
                    className="hero_img mt-5"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
