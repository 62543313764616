import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Hero from "./Hero";
import Statement1 from "./Statement1";
import Statement2 from "./Statement2";
import Statement3 from "./Statement3";
import Statement4 from "./Statement4";
import Statement5 from "./Statement5";
import Statement6 from "./Statement6";
import useScrollListener from "./UseScroll";
import "../Styles/Scroll.css";
import get_started_logo from "../Images/Artboard2.png";

function GetStarted() {
  const navigate = useNavigate();


  useEffect(() => {
    const user = localStorage.getItem("landing_page");
    if (user == 1 ) {
    
      navigate("/talent-rpo");
    }
    else{
    console.log(user, "user1");

    }
  }, []);

  let w = window.innerWidth;

  const [navClassList, setNavClassList] = useState([]);
  const scroll = useScrollListener();

  // update classList of nav on scroll
  useEffect(() => {
    const _classList = [];

    if (scroll.y > 150 && scroll.y - scroll.lastY > 0)
      _classList.push("nav-bar--hidden");

    setNavClassList(_classList);
  }, [scroll.y, scroll.lastY]);
  return (
    <div className="get_started">
      {w > 992 ? (
        <nav className={navClassList.join(" ")}>
          <div className="row">
            <div className="col-4"></div>
            <div className="col-4">
           <a href="/" target="">  <img alt="get-start-logo"
                src={get_started_logo}  
                style={{  objectFit:"cover", width:"206px", height:"46px"}}
              /></a> 
            </div>
            <div className="col-md-4">
              {" "}
              <a className="text-decoration-none" href="/talent-rpo">
                <span
                  style={{
                    background: "#9D4EDD",
                    padding: "16px 48px",
                    color: "white",
                    fontWeight: "700",
                    fontSize: "0.8rem",
                    borderRadius: "4px",
                  }}
                >
                  Get started
                </span>
              </a>
            </div>
          </div>{" "}
      
        </nav>
      ) : (
        <nav className={navClassList.join(" ")}>
          <div className="row">
            <div className="col-3">
             <a href="/"> <img alt="get_started_logo"
                src={get_started_logo}
                style={{  objectFit:"cover", width:"150px" }}
              ></img></a>
            </div>
            <div className="col-9">
              {" "}
              <a className="text-decoration-none float-end" href="/talent-rpo">
                {" "}
                <span
                  style={{
                    background: "#9D4EDD",
                    padding: "16px 48px",
                    color: "white",
                    fontWeight: "700",
                    fontSize: "0.8rem",
                    borderRadius: "4px",
                  }}
                >
                  Get started
                </span>
              </a>
            </div>
          </div>{" "}
      
        </nav>
      )}

      <Hero />
      <Statement1 />
      <Statement2 />
      <Statement3 />
      <Statement4 />
      <Statement5 />
      <Statement6 />
      <Footer />
    </div>
  );
}
export default GetStarted;
