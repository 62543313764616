import React, { useState } from "react";
import { Dropdown, ToggleButton } from "react-bootstrap";
import { FaBars, FaTimes } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import web_logo from "../Images/homepage_logo.png";
import "../Styles/Navbar.css";

const Navbar = () => {
  const navigate = useNavigate();

  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 0) {
      setColor(true);
    } else {
      setColor(true);
    }
  };
  window.addEventListener("scroll", changeColor);
  const activeMenu = useLocation();


  const getOpen = () => {
    localStorage.setItem("landing_page", 0);
    navigate("/");
    closeMenu();
  };

  const closeMenu = () => setClick(false);
  const [home, setHome] = useState("black");
  const [who, setWho] = useState("black");
  const [expertise, setExpertise] = useState("black");
  const [offering, setOfferings] = useState("black");
  const [reachOut, setReachOut] = useState("black");
  const [learn, setLearn] = useState("black");
  const handleHome = () => {
    setHome("#9D4EDD");
    setWho("black");
    setExpertise("black");
    setOfferings("black");
    setReachOut("black");
    setLearn("black");
    closeMenu();
  };
  const handleWho = () => {
    setHome("black");
    setWho("#9D4EDD");
    setExpertise("black");
    setOfferings("black");
    setReachOut("black");
    setLearn("black");
    closeMenu();
  };
  const handleExpertise = () => {
    setHome("black");
    setWho("black");
    setExpertise("#9D4EDD");
    setOfferings("black");
    setLearn("black");
    setReachOut("black");
    closeMenu();
  };
  const handleOfferings = () => {
    setHome("black");
    setWho("black");
    setExpertise("black");
    setOfferings("#9D4EDD");
    setReachOut("black");
    setLearn("black");
    closeMenu();
  };
  const handleReachOut = () => {
    setHome("black");
    setWho("black");
    setExpertise("black");
    setOfferings("black");
    setReachOut("#9D4EDD");
    setLearn("black");
    closeMenu();
  };
  const handleLearn = () => {
    setHome("black");
    setWho("black");
    setExpertise("black");
    setOfferings("black");
    setReachOut("black");
    setLearn("#9D4EDD");
    closeMenu();
  };
  return (
    <div
      className={color ? "header header-bg" : "header"}
      style={{ zIndex: "2" }}
    >
      <div className="container">
        <nav className="container navbar ">
          <div className="d-lg-flex" style={{ display: "flex" }}>
            <div>
              <a
                href="/talent-rpo"
                className="logo  text-decoration-none text-align-center text-white"
              >
                <img src={web_logo} alt="logo" style={{ width: "70%" }} />
              </a>
            </div>
            {/* <div >
              <span className="ps-5" style={{ fontSize: "1.3rem" }}>
                <b>Customer Challenges!</b>
              </span>
            </div> */}
          </div>

          <div className="hamburger" onClick={handleClick}>
            {click ? (
              <FaTimes size={20} style={{ color: "black" }} />
            ) : (
              <FaBars size={30} style={{ color: "black" }} />
            )}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item list-unstyled">
              <a
                // href="/"
                style={{
                  textDecoration: "none",
                  color: "#FF7900",
                  cursor: "pointer",
                  fontSize:"15px",
                  fontWeight:"700"
                }}
                onClick={getOpen}
              >
                Customer Challenges!
              </a>
            </li>
            <li className="nav-item list-unstyled">
              <a
                href="/talent-rpo#home"
                style={{ textDecoration: "none", color: home, }}
                onClick={handleHome}
              >
                Home
              </a>
            </li>

            <li className="nav-item list-unstyled">
              <a
                style={{ textDecoration: "none", color: "black", zIndex: "1" }}
                // onClick={closeMenu}
              >
                <Dropdown>
                  <Dropdown.Toggle
                    className="text-decoration-none "
                    style={{
                      background: "none",
                      border: "none",
                      color: who,
                      fontSize: "0.9rem",
                      fontWeight: "400",
                    }}
                  >
                    <span className="whoare">Who we are</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="/infrastructure"
                      onClick={handleWho}
                      style={{
                        color: "#9D4EDD",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                    >
                      Infrastructure
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      href="/teams"
                      onClick={handleWho}
                      style={{
                        color: "#9D4EDD",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                    >
                      Teams
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      href="/locations"
                      onClick={handleWho}
                      style={{
                        color: "#9D4EDD",
                        zIndex: "1",
                        backgroundColor: "white",
                      }}
                    >
                      Locations
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </a>
            </li>
           
            <li className="nav-item list-unstyled">
              <a
                href="/talent-rpo#services"
                style={{ textDecoration: "none", color: expertise }}
                onClick={handleExpertise}
              >
                Services
              </a>
            </li>
            <li className="nav-item list-unstyled">
              <a
                className="our_offering_link"
                href="/talent-rpo#engage-us"
                style={{ textDecoration: "none", color: offering }}
                onClick={handleOfferings}
              >
                Engage Us
              </a>
            </li>

            <li className="nav-item list-unstyled">
              <a
                href="/talent-rpo#reach-out"
                style={{ textDecoration: "none", color: reachOut }}
                onClick={handleReachOut}
              >
                Contact us
              </a>
            </li>

            <li className="nav-item list-unstyled">
              <a
                href="/talent-rpo#learn-earn"
                style={{ textDecoration: "none", color: learn }}
                onClick={handleLearn}
              >
                Learn & Earn
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
