import React, { useState, useRef, useEffect } from "react";
import phone_icon from "../Images/phone_icon.png";
import mail_icon from "../Images/mail_icon.png";
import location from "../Images/location_icon.png";
import "../Styles/ReachOut.css";
import axios from "axios";
import qs from "qs";
import thanking_tick_img from "../Images/thanking_tick_img.png";
import { MetroSpinner } from "react-spinners-kit";

function ReachOut() {
  const myRef = useRef();
  const [result, setResult] = useState();
  function scrollToComponent() {
    if (window.location.hash === "#reach-out") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }
  const [loading, setLoading] = useState(false);

  useEffect(() => scrollToComponent(), []);

  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleName = (e) => {
    setName(e.target.value.trimStart());
  };
  const handleEmail = (e) => {
    setEmail(e.target.value.trimStart());
  };
  const handleCompanyName = (e) => {
    setCompanyName(e.target.value.trimStart());
  };
  const handleMessage = (e) => {
    setMessage(e.target.value.trimStart());
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (result !== "SUCCESS") {
      setLoading(true);
    } else {
      setLoading(false);
    }
    const reqData = {
      email: email,
      company: companyName,
      name: name,
      message: message,
    };

    axios({
      method: "post",
      url: "https://dev-api.talentrpo.ai/contact-talent-rpo/",
      data: qs.stringify(reqData),
      header: { "Content-Type": "application/x-www-form-urlencoded","Accept":"*/*" },
    }).then((res) => {
      console.log(res, "data");
      setResult(res.data.RESULT);
      // setName("");
      // setCompanyName("");
      // setEmail("");
      // setMessage("");
    });

    console.log(reqData, "req");
  };
  let w = window.innerWidth;
  return (
    <div style={{ background: "#1E1A20" }}>
      <div className="reach_out_mobile" id="reach-out" ref={myRef}>
        <div className="container  mt-5">
          <div className="row mt-5 py-5 container ">
            <div className="col-md-6 mt-5 reach_text" align="left">
              {w > 900 ? (
                <div
                  className="reach_out_text text-white"
                  style={{
                    fontSize: "2.9rem",
                    fontWeight: "800",
                    lineHeight: "52px",
                  }}
                >
                  Ping Us!
                </div>
              ) : (
                <div
                  className="reach_out_text text-white"
                  style={{
                    fontSize: "2.4rem",
                    fontWeight: "800",
                    lineHeight: "52px",
                  }}
                >
                  Ping Us!
                </div>
              )}

              <div className="mt-5 ">
                {/* <div className="d-flex">
                  <div>
                    <img alt="icons" src={phone_icon}></img>
                  </div>
                  <div className="ps-3">
                    <a
                      className="text-decoration-none text-white"
                      href="tel:+732 534 3870"
                    >
                      732 534 3870
                    </a>
                  </div>
                </div> */}
              </div>
              <div className="mt-5">
                <div className="d-flex">
                  <div>
                    <img alt="icons" src={mail_icon}></img>
                  </div>
                  <div className="ps-3 ">
                    {" "}
                    <a
                      className="text-decoration-none text-white"
                      href="mailto:+contact@talentrpo.ai"
                    >
                      contact@talentrpo.ai
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="d-flex text-white">
                  <div>
                    <img alt="icons" src={location}></img>
                  </div>
                  <div className="ps-3">254 Route 34 Matawan NJ 07747</div>
                </div>
              </div>
            </div>
            {w > 900 ? (
              <div
                className="col-md-6 conatiner"
                align="end"
                style={{ color: "#FFFFFF" }}
              >
                <form onSubmit={handleSubmit}>
                  <div style={{ fontSize: "1rem" }}>
                    <input
                      className="ps-3 w-75"
                      type="text"
                      value={name}
                      maxLength="23"
                      onChange={handleName}
                      style={{
                        height: "60px",
                        borderRadius: "4px",
                        border: "none",
                      }}
                      placeholder="Name"
                      required
                    />
                  </div>

                  <div className="mt-3" style={{ fontSize: "1rem" }}>
                    <input
                      className="ps-3 w-75"
                      type="text"
                      value={companyName}
                      onChange={handleCompanyName}
                      style={{
                        height: "60px",
                        borderRadius: "4px",
                        border: "none",
                      }}
                      id="phone"
                      name="text"
                      placeholder="Company name"
                      required
                    />{" "}
                  </div>
                  <div className="mt-3" style={{ fontSize: "1rem" }}>
                    {" "}
                    <input
                      value={email}
                      onChange={handleEmail}
                      className="ps-3 w-75"
                      type="email"
                      style={{
                        height: "60px",
                        borderRadius: "4px",
                        border: "none",
                      }}
                      placeholder="Email address"
                      required
                    />
                  </div>

                  <div className="mt-3" style={{ fontSize: "1rem" }}>
                    <textarea
                      className="ps-3 w-75 p-3"
                      value={message}
                      onChange={handleMessage}
                      type="text"
                      style={{
                        height: "120px",
                        fontWeight: "400",
                        width: "220px",
                        borderRadius: "4px",
                        border: "none",
                      }}
                      placeholder="Message"
                      required
                    />
                  </div>
                  {result === "SUCCESS" ? (
                    <div className="mt-3">
                      <span
                        className="btn  w-75"
                        style={{
                          height: "56px",
                          border: "1px solid #02EE6A",
                          background: "#02EE6A14",
                          color: "#02EE6A",
                          borderRadius: "4px",
                          fontWeight: "400",
                          fontSize: "0.8rem",
                          lineHeight: "24px",
                          fontWeight: "500",
                        }}
                        placeholder="SUBMIT"
                      >
                        <div className="py-2">
                          <span>
                            <img
                              alt="thanking_tick_img"
                              src={thanking_tick_img}
                            ></img>
                          </span>
                          <span className="ps-2">
                            {" "}
                            Thanks for submitting, will get back soon
                          </span>
                        </div>
                      </span>
                    </div>
                  ) : (
                    <div className="mt-3">
                      <button
                        type="submit"
                        className="btn  w-75"
                        style={{
                          height: "56px",
                          fontSize: "1rem",
                          lineHeight: "24px",
                          fontWeight: "600",
                          background: "#9D4EDD",
                          color: "#FFFFFF",
                          // textAlign:"center",
                          borderRadius: "4px",
                        }}
                        placeholder="SUBMIT"
                      >
                        <div
                          className="d-flex justify-content-center mt-1"
                          align="center"
                          style={{ textAlign: "center" }}
                        >
                          <div className="text-align-center">Submit</div>
                          <div className="ps-3">
                            <MetroSpinner
                              size={20}
                              color="white"
                              loading={loading}
                            />
                          </div>
                        </div>
                      </button>
                    </div>
                  )}
                </form>
              </div>
            ) : (
              <div
                className="col-md-6 conatiner mt-3"
                align="center"
                style={{ color: "#FAFAFA" }}
              >
                <div className="p-3">
                  <form onSubmit={handleSubmit}>
                    <div style={{ fontSize: "1rem" }}>
                      <input
                        className="ps-3 w-100"
                        type="text"
                        value={name}
                        onChange={handleName}
                        style={{
                          height: "60px",
                          borderRadius: "4px",
                          border: "none",
                          background: "#FAFAFA",
                        }}
                        placeholder="Name"
                        required
                      />
                    </div>

                    <div className="mt-3" style={{ fontSize: "1rem" }}>
                      <input
                        className="ps-3 w-100"
                        type="text"
                        value={companyName}
                        onChange={handleCompanyName}
                        style={{
                          height: "60px",
                          borderRadius: "4px",
                          border: "none",
                          background: "#FAFAFA",
                        }}
                        id="phone"
                        name="text"
                        placeholder="Company name"
                        required
                      />{" "}
                    </div>
                    <div className="mt-3" style={{ fontSize: "1rem" }}>
                      {" "}
                      <input
                        className="ps-3 w-100"
                        type="email"
                        value={email}
                        onChange={handleEmail}
                        style={{
                          height: "60px",
                          borderRadius: "4px",
                          border: "none",
                          background: "#FAFAFA",
                        }}
                        placeholder="Email address"
                        required
                      />
                    </div>

                    <div className="mt-3" style={{ fontSize: "1rem" }}>
                      <textarea
                        className="ps-3 w-100 p-3"
                        type="text"
                        value={message}
                        onChange={handleMessage}
                        style={{
                          height: "120px",
                          borderRadius: "4px",
                          border: "none",
                          background: "#FAFAFA",
                        }}
                        placeholder="Message"
                        required
                      />
                    </div>
                    {result == "SUCCESS" ? (
                      <div className="mt-3">
                        <span
                          className="btn  w-100"
                          style={{
                            height: "56px",
                            border: "1px solid #02EE6A",
                            background: "#02EE6A14",
                            color: "#02EE6A",
                            borderRadius: "4px",
                            fontSize: "0.4rem",

                            fontWeight: "400",
                          }}
                          placeholder="SUBMIT"
                        >
                          <div className="py-2">
                            <span>
                              <img
                                alt="thanking_tick_img"
                                src={thanking_tick_img}
                              ></img>
                            </span>
                            <span className="ps-2">
                              {" "}
                              Thanks for submitting, will get back soon
                            </span>
                          </div>
                        </span>
                      </div>
                    ) : (
                      <div className="mt-3">
                        <button
                          type="submit"
                          className="btn  w-100"
                          style={{
                            height: "56px",
                            // fontWeight:"400",
                            // fontSize:"1rem",
                            background: "#9D4EDD",
                            color: "#FFFFFF",
                            borderRadius: "4px",
                          }}
                          placeholder="SUBMIT"
                        >
                          <div
                            className="d-flex justify-content-center mt-1"
                            align="center"
                          >
                            <div
                              align="center"
                              style={{
                                textAlign: "center",
                                fontWeight: "400",
                                fontSize: "0.8rem",
                              }}
                            >
                              Submit
                            </div>
                            <div className="ps-3">
                              <MetroSpinner
                                size={20}
                                color="white"
                                loading={loading}
                              />
                            </div>
                          </div>
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ReachOut;
