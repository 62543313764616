import React, { useState } from "react";
import "../Styles/Faq.css";

const Faq = (props) => {
  const accordionData = [
    {
      title: "What is Recruitment Process Outsourcing (RPO)?",
      content: `RPO stands for recruitment process outsourcing,
     which is the continual outsourcing of all or a portion of the recruitment process to an
      outside partner. The RPO teams up recruiters that adhere to your culture, represent your brand 
      and are supported by the most recent talent acquisition tools, technology, and knowledge so
       you can hire better candidates more quickly and have a positive effect on your business.`,
    },
    {
      title: "How is Talent RPO different from a recruitment agency?",
      content: `Talent RPO's End-to-End RPO process is designed, planned,
     and carried out with international recruitment procedures serving as the backbone.
      We have a decade of expertise assisting businesses of all sizes in finding the best candidates. 
      Aside from those already stated, TalentRPO allows you to establish a new collaboration, much like 
      the several well-known brands in the United States who depend on us for our performance-driven results.`,
    },

    {
      title: " How would our RPO Team Collaborate with your organization?",
      content: `In order to help companies across industries stay ahead of the curve by locating and
     putting the greatest match in this competitive market, we have a specialized team of skilled and 
     knowledgeable individuals who are periodically taught the latest tools and technologies.`,
    },
    {
      title: "Can Talent RPO customize services according to my requirements?",
      content: `A professional RPO specialist will work in accordance with your recruitment needs,
     taking into account the sector, the position for which you are hiring, and other relevant aspects. 
     Consider the fact that, in order to achieve the desired result, employing C-Suite executives requires a 
     unique approach and C-Suite hiring specialists.`,
    },
    {
      title: "What is the advantage of choosing recruiters from Talent  RPO?",
      content: `The RPO team will collaborate with you to evaluate your recruitment situation and 
    develop a solution that is customized to your unique requirements. In order to fill your available 
    positions, Our RPO recruiters work as an extension of your team, utilising their knowledge and technology
     while interacting with prospects in a way that makes them feel like they are a part of your company
      and preserving your reputation.`,
    },
    {
      title: "What is Recruitment Process Outsourcing (RPO)?",
      content: `The search for a recruitment partner might be justified tactically or strategically
    . A company's own staff may not have the resources, time, or capacity to meet the demand for talent.
     Or perhaps there is a desire to completely revamp the hiring procedure. The solution could be outside
      assistance.`,
    },
  ];
  const [selected, setSelected] = useState(false);
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(true);
    }
    setSelected(i);
  };


  return (
    <div className="container mt-5 p-md-5">
      <div
        className=""
        align="center"
        style={{ fontWeight: "800", fontSize: "2.9rem", lineHeight: "52px" }}
      >
        FAQ'S
      </div>{" "}
      <div className="container">
        <div className="mt-5 row">
         
          {accordionData.map((item, i) => {
            return (
              <div className="col-lg-6 " key={i}>
                {" "}
            
                <div className="accordion " >
                  <div className={"accordion-item" + props.color}>
                    <div className="accordion-title" onClick={() => toggle(i)}>
                      <div>{item.title}</div>
                      {selected === i ? (
                        <div style={{ color: "#9D4EDD", fontSize: "2rem" }}>
                          -
                        </div>
                      ) : (
                        <div style={{ color: "#9D4EDD", fontSize: "2rem" }}>
                          +
                        </div>
                      )}
                    </div>
                    {selected === i ? (
                      <div className="accordion-content">{item.content}</div>
                    ) : (
                      <div ></div>
                    )}
                    
                  </div>
                </div>
              </div>
            );
          })}

         
        </div>
      </div>
    </div>
  );
};

export default Faq;


