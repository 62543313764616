import React from "react";
import statement4 from "../Images/statement4.png";
function Statement4() {
  let w = window.innerWidth;
  return (
    <div style={{ background: "#121014" }}  >
      <div
        className="py-5 container text-white"
        style={{ fontFamily: "Inter" }}
      >
        <div className="mt-5 row">
          {w > 992 ? (
            <div className="col-md-6 ">
              <img
                src={statement4}
                alt="insufficient"
                style={{ width: "100%" }}
              ></img>
            </div>
          ) : (
            <div className="col-md-6 " data-aos="fade-up" data-aos-duration="1000">
            <div className="mt-5">
              <div style={{ fontSize: "0.8rem" }}>My Challenge?</div>
              <div className=""
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "800",
                  color: "#9D4EDD",
                  lineHeight: "50px",
                }}
              >
                Underperforming
              </div>
              <div style={{ fontSize: "2.3rem", fontWeight: "700" }}>
                Recruiters
              </div>

              <div className="d-flex mt-3">
                <div
                  className="mt-2"
                  style={{
                    width: "20px",
                    height: "8px",
                    background: "#FF7900",
                    lineHeight: "24px",
                  }}
                ></div>
                <div className="ps-2" style={{fontSize:"0.9rem"}}>Inadequate experience with Tools & Software</div>
              </div>
             

            
              <div className="d-flex mt-3">
                <div
                  className="mt-2"
                  style={{
                    width: "20px",
                    height: "8px",
                    background: "#FF7900",
                    lineHeight: "24px",
                  }}
                ></div>
                <div className="ps-2"  style={{fontSize:"0.9rem"}}>Lack of Time & Task management</div>
              </div>
              <div className="d-flex mt-3">
            <div
              className="mt-2"
              style={{
                width: "20px",
                height: "8px",
                background: "#FF7900",
                lineHeight: "24px",
              }}
            ></div>
            <div className="ps-2"  style={{fontSize:"0.9rem"}}> Dearth of Passion for recruitment</div>
          </div>
          <div className="d-flex mt-3">
            <div
              className="mt-2"
              style={{
                width: "20px",
                height: "8px",
                background: "#FF7900",
                lineHeight: "24px",
              }}
            ></div>
            <div className="ps-2"  style={{fontSize:"0.9rem"}}>Lack of technical knowledge</div>
          </div>
          <div className="d-flex mt-3">
            <div
              className="mt-2"
              style={{
                width: "20px",
                height: "8px",
                background: "#FF7900",
                lineHeight: "24px",
              }}
            ></div>
            <div className="ps-2"  style={{fontSize:"0.9rem"}}>Poor communication Skills</div>
          </div>
            </div>
          </div>
          )}
          {w > 992 ? (
            <div className="col-md-6 mt-5" data-aos="fade-up" data-aos-duration="1000">
              <div className="mt-5">
                <div style={{ fontSize: "0.8rem" }}>My Challenge?</div>
                <div
                  style={{
                    fontSize: "4.2rem",
                    fontWeight: "800",
                    color: "#9D4EDD",
                    lineHeight: "86px",
                  }}
                >
                  Underperforming
                </div>
                <div style={{ fontSize: "4rem", fontWeight: "700" }}>
                  Recruiters
                </div>

              
                <div className="d-flex mt-3">
                  <div
                    className="mt-2"
                    style={{
                      width: "20px",
                      height: "8px",
                      background: "#FF7900",
                      lineHeight: "24px",
                    }}
                  ></div>
                  <div className="ps-2">Inadequate experience with Tools & Software</div>
                </div>
               

              
                <div className="d-flex mt-3">
                  <div
                    className="mt-2"
                    style={{
                      width: "20px",
                      height: "8px",
                      background: "#FF7900",
                      lineHeight: "24px",
                    }}
                  ></div>
                  <div className="ps-2">Lack of Time & Task management</div>
                </div>
                <div className="d-flex mt-3">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div className="ps-2">Dearth of Passion for recruitment</div>
            </div>
            <div className="d-flex mt-3">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div className="ps-2">Lack of technical knowledge</div>
            </div>
            <div className="d-flex mt-3">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#FF7900",
                  lineHeight: "24px",
                }}
              ></div>
              <div className="ps-2">Poor communication Skills</div>
            </div>
              </div>
            </div>
          ) : (
          
             <div
              className="mt-3 col-lg-6 justify-content-center d-flex align-content-center"
              align="center"
            >
              <img
                src={statement4}
                alt="insufficient"
                style={{ width: "80%" }}
              ></img>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Statement4;
