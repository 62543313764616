import React, { useEffect, useRef } from "react";
import website_footer_logo from "../Images/website_footer_logo.png";
import linkedin_icon from "../Images/linkedin_icon.svg";
import twitter_icon from "../Images/twitter_icon.svg";
import insta_icon from "../Images/insta_icon.svg";
import youtube_icon from "../Images/youtube_icon.svg";
import "../Styles/WebsiteFooter.css";
function WebsiteFooter() {
  let w = window.innerWidth;
  return (
    <div style={{ background: "#121014" }}>
      <div className="mt-5 container" style={{ color: "#FFFFFF" }}>
        <div className="mt-5 row container py-5">
          <div className="col-md-3">
            <div>
              <img alt="footer_icons" src={website_footer_logo}></img>
            </div>
            {w > 992 ? (
              <div
                className="py-3 ps-2"
                style={{
                  color: "#7D7A7E",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "28px",
                }}
              >
                We don't compromise on the quality, we emphasize them!
              </div>
            ) : (
              <div
                className="py-3 ps-2 "
                style={{
                  color: "#7D7A7E",
                  fontWeight: "400",
                  fontSize: "1rem",
                  lineHeight: "20px",
                }}
              >
                We don't compromise on the quality, we emphasize them!
              </div>
            )}
            <a
              href="/talent-rpo#reach-out"
              className="text-decoration-none text-white"
            >
              <div
                className="btn p-3 "
                style={{
                  backgroundColor: "#9D4EDD",
                  width: "247px",
                  height: "56px",
                  gap: "12px",
                  padding: "16px 48px ",
                  color: "white",
                  fontWeight: "700",
                  fontSize: "1rem",
                  lineHeight: "1.25rem",
                }}
              >
                Get Free Trial
              </div>{" "}
            </a>
          </div>{" "}
          {w > 996 ? (
            <div className="col-md-5 container px-5 footer_text">
              <div
                className=""
                style={{
                  fontWeight: "900",
                  fontSize: "2.9rem",
                  lineHeight: "58px",
                }}
              >
                <b>
                  {" "}
                  TalentRPO{" "}
                  <span style={{ color: "#9D4EDD" }}>
                    Recruitment <br />
                    Nerds.{" "}
                  </span>
                </b>
              </div>
              {/* <div className="ps-5">
              Talent RPO{" "}
              <span style={{ color: "#9D4EDD" }}>
                Recruitment <br />
                Nerds.{" "}
              </span>
            </div> */}
            </div>
          ) : (
            <div className="container" align="center">
              <div
                className="mt-3"
                align="left"
                style={{
                  fontWeight: "900",
                  fontSize: "2.3rem",
                  lineHeight: "48px",
                }}
              >
                <b>
                  {" "}
                  TalentRPO <br />
                  <span style={{ color: "#9D4EDD" }}>Recruitment Nerds. </span>
                </b>
              </div>
            </div>
          )}{" "}
          {w > 996 ? (
            <div className="col-lg-4 col-md-6">
              <div className="float-end">
                <div>Social Media</div>
                <div
                  className="mt-2"
                  style={{
                    color: "#7D7A7E",
                    fontSize: "0.9rem",
                    fontWeight: "400",
                  }}
                ></div>
                <div className="row ">
                  <div className="col-6 d-flex justify-content-between mt-2 ">
                    <div>
                      <a
                        href="https://www.linkedin.com/company/talent-rpo-ai
"
                        className="text-decoration-none"
                        target="_blank"
                      >
                        <img
                          alt="footer_icons"
                          src={linkedin_icon}
                          className="follow_links"
                        ></img>
                      </a>
                    </div>

                    <div>
                      <a
                        href="https://twitter.com/talentrpo"
                        className="text-decoration-none"
                        target="_blank"
                      >
                        <img
                          alt="footer_icons"
                          className="follow_links"
                          src={twitter_icon}
                        ></img>{" "}
                      </a>
                    </div>
                    <div>
                      {" "}
                      <a
                        href="https://www.instagram.com/talentrpoai/"
                        className="text-decoration-none"
                        target="_blank"
                      >
                        <img
                          alt="footer_icons"
                          className="follow_links"
                          src={insta_icon}
                        ></img>
                      </a>
                    </div>
                    <div>
                      {" "}
                      <a
                        href="https://www.youtube.com/channel/UCERf7zoqHZgvhASs2qXrnuA"
                        className="text-decoration-none"
                        target="_blank"
                      >
                        <img
                          alt="footer_icons"
                          className="follow_links"
                          src={youtube_icon}
                        ></img>
                      </a>
                    </div>
                  </div>
                  <div className="col-6"></div>{" "}
                </div>
                <div
                  className="mt-5 row"
                  style={{ color: "#7D7A7E", fontSize: "0.9rem" }}
                >
                  <div className="col-md-6 mt-5">
                    <a
                      className="text-decoration-none"
                      style={{ color: "#7D7A7E" }}
                      href="mailto:+contact@talentrpo.ai"
                    >
                      contact@talentrpo.ai
                    </a>
                  </div>
                  <div className="col-md-6 ps-4 mt-5">
                    <a
                      className="text-decoration-none "
                      style={{ color: "#7D7A7E", visibility: "hidden" }}
                      href="tel:+732 534 3870"
                    >
                      732 534 3870
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <div className=" mt-3">
                <div>Social Media</div>
                <div
                  className="mt-2"
                  style={{
                    color: "#7D7A7E",
                    fontSize: "0.9rem",
                    fontWeight: "400",
                  }}
                ></div>
                <div className="row " align="left">
                  <div
                    align="center"
                    className="col-6 d-flex justify-content-between mt-2 "
                  >
                    <div>
                      <a
                        href="https://www.linkedin.com/company/talent-rpo-ai
"
                        className="text-decoration-none"
                        target="_blank"
                      >
                        <img
                          alt="footer_icons"
                          src={linkedin_icon}
                          className="follow_links"
                        ></img>
                      </a>
                    </div>

                    <div>
                      <a
                        href="https://twitter.com/talentrpo"
                        className="text-decoration-none"
                        target="_blank"
                      >
                        <img
                          alt="footer_icons"
                          className="follow_links"
                          src={twitter_icon}
                        ></img>{" "}
                      </a>
                    </div>
                    <div>
                      {" "}
                      <a
                        href="https://www.instagram.com/talentrpoai/"
                        className="text-decoration-none"
                        target="_blank"
                      >
                        <img
                          alt="footer_icons"
                          className="follow_links"
                          src={insta_icon}
                        ></img>
                      </a>
                    </div>
                    <div>
                      {" "}
                      <a
                        href="https://www.youtube.com/channel/UCERf7zoqHZgvhASs2qXrnuA"
                        className="text-decoration-none"
                        target="_blank"
                      >
                        <img
                          alt="footer_icons"
                          className="follow_links"
                          src={youtube_icon}
                        ></img>
                      </a>
                    </div>
                  </div>
                  {/* <div className="col-6"></div>{" "} */}
                </div>
                <div
                  className="mt-5 row"
                  align="left"
                  style={{ color: "#7D7A7E", fontSize: "0.9rem" }}
                >
                  <div className="col-md-6 ">
                    <a
                      className="text-decoration-none"
                      style={{ color: "#7D7A7E" }}
                      href="mailto:+contact@talentrpo.ai"
                    >
                      contact@talentrpo.ai
                    </a>
                  </div>
                  <div className="col-md-6 ">
                    <a
                      className="text-decoration-none "
                      style={{ color: "#7D7A7E", visibility: "hidden" }}
                      href="tel:+732 534 3870"
                    >
                      732 534 3870
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}{" "}
        </div>
        <div
          className="pb-2 d-lg-flex justify-content-between"
          style={{ borderTop: "2px solid #7D7A7E", fontSize: "0.9rem" }}
        >
          <div className="mt-4 d-flex ">
            <span className="" style={{ fontSize: "1.6rem" }}>
              ©{" "}
            </span>
            <span className="mt-2 ps-2"> 2024 All rights received</span>
          </div>
          <div className="d-flex mt-4 privacy_policy_text">
            <a
              href="/terms-and-conditions"
              className="text-decoration-none text-white"
            >
              Terms of use
            </a>
            <a
              className="ps-5 text-decoration-none text-white"
              href="/privacy-policy"
            >
              Privacy Policy
            </a>
            <a
              className="ps-5 text-decoration-none text-white"
              href="/cookies-policy"
            >
              Cookies Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default WebsiteFooter;
