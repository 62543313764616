import React from "react";
import statement3_right_img from "../Images/statement3_right_img.png";
function Statement3() {
  let w = window.innerWidth;
  return (
    <div style={{ background: "#121014", fontFamily: "Inter" }} className="">
      <div className=" container text-white" style={{ fontFamily: "Inter" }}>
        <div className="row">
          {" "}
          {w > 992 ? (
            <div
              className=" col-md-4 mt-5 p-5 "
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="py-5 mt-5" >
                <div className=" mt-5" style={{ fontSize: "0.8rem" }}>
                  My Challenge?
                </div>
                {w > 992 ? (
                  <div
                    style={{
                      fontSize: "4.2rem",
                      fontWeight: "700",
                      lineHeight: "86px",
                    }}
                  >
                    <div style={{ color: "#FF7900" }}>Inefficient</div>
                    <div>Recruitment Process</div>
                  </div>
                ) : (
                  <div 
                    className="mt-3"
                    style={{
                      fontSize: "3.5rem",
                      fontWeight: "700",
                      lineHeight: "60px",
                    }}
                  >
                    <div style={{ color: "#FF7900" }}>Inefficient</div>
                    <div>Recruitment Process</div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className=" col-md-4  " data-aos="fade-up" data-aos-duration="1000">
              <div className="">
                <div className="" style={{ fontSize: "0.8rem" }}>
                  My Challenge?
                </div>
                {w > 992 ? (
                  <div
                    style={{
                      fontSize: "4.2rem",
                      fontWeight: "700",
                      lineHeight: "86px",
                    }}
                  >
                    <div style={{ color: "#FF7900" }}>Inefficient</div>
                    <div>Recruitment Process</div>
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: "3.2rem",
                      fontWeight: "700",
                      lineHeight: "56px",
                    }}
                  >
                    <div style={{ color: "#FF7900" }}>Inefficient</div>
                    <div>Recruitment Process</div>
                  </div>
                )}
              </div>
            </div>
          )}
          {w > 992 ? (
            <div className="col-md-7">
              <div
                style={{
                  background: "#9D4EDD",
                  // height: "2px",
                  width: "360px",
                  position: "relative",
                  top: "70%",
                  fontSize: "0.9rem",
                  fontWeight: "700",
                  // left: "10%",
                }}
                className=" p-4"
              >
                <div className="ps-1 p-2">
                  <span style={{ color: "#FF7900" }}> ● </span>
                  <span className="ps-1">
                    High attrition - Recruiters frustration
                  </span>
                </div>

                <div className="ps-1 p-2">
                  {" "}
                  <span style={{ color: "#FF7900" }}> ● </span>
                  <span className="ps-1">Manual & Tiring sourcing process</span>
                </div>

                <div className="ps-1 p-2">
                  {" "}
                  <span style={{ color: "#FF7900" }}> ● </span>
                  <span className="ps-1">Inefficient qualifying process</span>
                </div>
              </div>
              <div>
                <img
                  className="float-end ps-5"
                  src={statement3_right_img}
                  alt="inefficient"
                  style={{ width: "100%", backgroundSize: "cover" }}
                ></img>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  background: "#9D4EDD",
                  // height: "2px",
                  width: "280px",
                  position: "relative",
                  top: "70%",
                  fontSize: "0.8rem",
                  fontWeight: "700",
                  // left: "10%",
                }}
                className=""
              >
                <div className="ps-1 p-2">
                  <span style={{ color: "#FF7900" }}> ● </span>
                  <span className="ps-1">
                    High attrition - Recruiters frustration
                  </span>
                </div>

                <div className="ps-1 p-2">
                  {" "}
                  <span style={{ color: "#FF7900" }}> ● </span>
                  <span className="ps-1">Manual & Tiring sourcing process</span>
                </div>

                <div className="ps-1 p-2">
                  {" "}
                  <span style={{ color: "#FF7900" }}> ● </span>
                  <span className="ps-1">Inefficient qualifying process</span>
                </div>
              </div>
              <div>
                <img
                  className="float-end ps-5"
                  src={statement3_right_img}
                  alt="inefficient"
                  align="center"
                  style={{ width: "90%" }}
                ></img>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Statement3;
