import React, { useEffect, useState } from "react";
import "./App.css";
import GetStarted from "./Components/GetStarted";
import Homepage from "./Components/Homepage";
// import { BrowserRouter  } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Infrastructure from "./Components/Infrastructure";
import Locations from "./Components/Locations";
import OurTeam from "./Components/OurTeam";
import Terms from "./Components/TermsAndConditions";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import CookiesPolicy from "./Components/CookiesPolicy";
import LearnAndEarn from "./Components/LearnAndEarn";

import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  Aos.init();
  Aos.refresh();

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<GetStarted />} />
          {/* <Route path="/get-started" element={<GetStarted />} /> */}
          <Route path="/talent-rpo" element={<Homepage />} />
          <Route path="/infrastructure" element={<Infrastructure />} />
          <Route path="/locations" element={<Locations />} />
          {/* <Route path="/teams" element={<OurTeam />} /> */}
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/learn-and-earn" element={<LearnAndEarn />} />
        </Routes>
       
       
      </BrowserRouter>
    </div>
  );
}

export default App;
