import React from "react";
import telcel from "../Images/telcel_logo.svg";
import Perdue from "../Images/Perdue.svg";
import saplogo from "../Images/sap_logo.svg";
import sonafi from "../Images/sonafi.svg";
import MetroOil from "../Images/MetroOil.svg";
import ford_logo from "../Images/ford-logo.svg";
import technologico_logo from "../Images/technologico.svg";
import PartyCity from "../Images/PartyCity.svg";
import newyork from "../Images/newyork.svg";
import Ericsson from "../Images/Ericsson.svg";
import Hess from "../Images/Hess.svg";
import Bayer from "../Images/Bayer.svg";
import standard from "../Images/standard.svg";
import Oracle from "../Images/Oracle.svg";
import Banamex from "../Images/Banamex.svg";
import miyano from "../Images/miyano.svg";
import Gemalto from "../Images/Gemalto.svg";
import Bontonpng from "../Images/Bontonpng.svg";
import Sirius from "../Images/Sirius.svg";
import ross from "../Images/ross.png";
import Biolink from "../Images/Biolink.svg";
import MCX from "../Images/MCX.svg";
import amazon from "../Images/amazon.svg";
import calidrux from "../Images/calidrux.svg";
import "../Styles/PartnersSection.css";
export default function PartnersSection() {
  return (
    <div className="">
      <div align="center">
        <div
          className="p-4 clients_heading"
          style={{
            color: "#151216",
            lineHeight: "52px",
            fontSize: "2.9rem",
            fontWeight: "800",
            background: "#1E1A20",
            color: "white",
          }}
        >
          Our Esteemed Clients
        </div>

        <div
          className="container  py-5 row justify-content-between clients"
          align="center"
        >
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={telcel}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={Perdue}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={saplogo}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={sonafi}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={MetroOil}
             
              className="esteemed_image"
            />
          </div>

          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={ford_logo}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={technologico_logo}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={PartyCity}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={newyork}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={Ericsson}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={Hess}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={Bayer}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={standard}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={Oracle}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={Banamex}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={miyano}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={Gemalto}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={Bontonpng}
             
              className="esteemed_image"
            />
          </div>
        
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={Sirius}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={ross}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={Biolink}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={MCX}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={amazon}
             
              className="esteemed_image"
            />
          </div>
          <div className="col-md-2 p-4">
            <img
              alt="sponsors_logo"
              src={calidrux}
             
              className="esteemed_image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
