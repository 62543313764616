import React from "react";

function Footer() {
  let w = window.innerWidth;
  return (
    <div  
      style={{
        background: "#121014",
        fontFamily: "Inter",
        height: "100vh",
        padding: "5%",
      }}
      align="center"
    >
      {w > 992 ? (
        <div className="p-5 container text-white" data-aos="fade-up" data-aos-duration="1000">
          <div
            className="mt-5 py-0"
            style={{
              fontSize: "4.8rem",
              fontWeight: "800",
              lineHeight: "120%",
              color: "#FFFFFF",
            }}
          >
            <b>
              {" "}
              Hey <span style={{ color: "#9D4EDD" }}>TalentRPO</span> Team!!!
            </b>
          </div>
          <div className="mt-2" style={{ fontSize: "37px", fontWeight: "500" }}>
            <span style={{ color: "#A3A1A4" }}>HELP ME FIX</span>{" "}
            <span style={{ color: "#FF7900" }}>MY ISSUES</span>{" "}
          </div>
        </div>
      ) : (
        <div data-aos="fade-up" data-aos-duration="1000"
          className="mt-5 py-5 container text-white "
          style={{
            fontSize: "2.5rem",
            fontWeight: "800",
            lineHeight: "60px",
            color: "#FFFFFF",
          }}
        >
          <div className="">
            <b>
              {" "}
              Hey <span style={{ color: "#9D4EDD" }}>TalentRPO</span> Team!!!
            </b>
          </div>
          <div className="mt-2" style={{ fontSize: "17px", fontWeight: "500" }}>
            <span style={{ color: "#A3A1A4" }}>HELP ME FIX</span>{" "}
            <span style={{ color: "#FF7900" }}>MY ISSUES</span>{" "}
          </div>
        </div>
      )}

      {/* <div
        className=""
        style={{ color: "#A3A1A4", fontSize: "1.2rem", lineHeight: "32px" }}
      >
        Come experience and feel the change . We help you find the right
        <br /> people to drive your business
      </div> */}
      {w > 992 ? (
        <div className="" data-aos="fade-up" data-aos-duration="1000">
          <a href="/talent-rpo">
            <div
              className="text-decoration-none btn mb-5"
              style={{
                backgroundColor: "#9D4EDD",
                padding: "30px 98px",
                color: "#FFFFFF",
                fontSize: "29px",
                fontWeight: "700",
                lineHeight: "32px",
              }}
            >
              Let me Get Started
            </div>
          </a>
        </div>
      ) : (
        <div className="" data-aos="fade-up" data-aos-duration="1000">
          <a href="/talent-rpo">
            <div
              className="text-decoration-none btn "
              style={{
                backgroundColor: "#9D4EDD",
                padding: "30px 38px",
                color: "#FFFFFF",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "32px",
              }}
            >
              Let me Get Started
            </div>
          </a>
        </div>
      )}
    </div>
  );
}
export default Footer;
