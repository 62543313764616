import React, { useEffect, useRef } from "react";
import "../Styles/GradientVideo.css";
import learn_video from "../Images/learn_earn.mp4";
import download_pdf from "../Images/TalentRPO-Brochure.pdf";

function GradientVideo() {
  const myRef = useRef();
  let w = window.innerWidth;
  function scrollToComponent() {
    if (window.location.hash === "#learn-earn") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);
  return (
    <div className="container mt-5 py-5" id="learn-earn" ref={myRef}>
      <div
        align="center"
        style={{
          fontWeight: "800",
          fontSize: "2.9rem",
          lineHeight: "52px",
          color: "black",
        }}
      >
        Explore Career in Talent Acquisition
      </div>
      {/* <a href="/learn-and-earn" className="text-decoration-none"> */}
      <div className="video-wrapper mt-5">
        <div className="video py-5">
          <div className="video-body">
            <div className="row container ">
              {w > 992 ? (
                <div className="col-md-12 p-5 py-3">
                  <div
                    className="px-5"
                    style={{
                      fontWeight: "800",
                      fontSize: "2.9rem",
                      lineHeight: "52px",
                      color: "#FFFFFF",
                    }}
                  >
                   Learn & Earn
                  </div>
                  <div
                    className="mt-2 px-5"
                    style={{
                      fontSize: "26px",
                      fontWeight: "600",
                      color: "white",
                      lineHeight: "28px",
                    }}
                  >
                    Join Our Job Oriented Handson Training Program.
                  </div>
                  <div className="mt-4 px-5">
                    <a
                      href={download_pdf}
                      target="_blank"
                      className="text-decoration-none border-none"
                    >
                      <button
                        className="btn "
                        style={{
                          color: "white",
                          fontSize: "0.9rem",
                          fontWeight: "700",
                          background: "#FF7900",
                          height: "56px",
                          width: "180px",
                          borderRadius: "4px",
                        }}
                      >
                        Learn More
                      </button>
                    </a>
                  </div>
                </div>
              ) : (
                <div className="" align="LEFT">
                  <div
                    style={{
                      fontWeight: "800",
                      fontSize: "2rem",
                      lineHeight: "52px",
                      color: "white",
                    }}
                  >
                    Learn & Earn
                  </div>
                  <div
                    
                    className="mt-2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "white",
                      lineHeight: "28px",
                    }}
                  >
                    Join Our Job Oriented Handson Training Program.
                  </div>
                  <div className="mt-4 ">
                    <a
                      href={download_pdf}
                      target="_blank"
                      className="text-decoration-none border-none"
                    >
                      <button
                        className="btn"
                        style={{
                          color: "white",
                          fontSize: "0.9rem",
                          fontWeight: "600",
                          background: "#FF7900",
                          height: "50px",
                          width: "200px",
                          borderRadius: "4px",
                        }}
                      >
                        Learn More
                      </button>
                    </a>
                  </div>
                </div>
              )}

              <div className="col-md-6"></div>
            </div>
          </div>
        </div>
        <video
          autoPlay
          width="100%"
          height="304px"
          style={{ zIndex: "-1", objectFit: "cover" }}
          muted
          loop
        >
          <source src={learn_video} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>
      {/* </a> */}
    </div>
  );
}
export default GradientVideo;
