import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import engageus from "../Images/engage_img.svg";
function EngageUs() {
  let w = window.innerWidth;
  const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "#engage-us") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);


  return (
    <div>
      <div className="container py-5 mt-5" id="engage-us" ref={myRef} >
        <div
          align="center"
          style={{ color: "#151216", fontSize: "46px", fontWeight: "800" }}
        >
          Engage With Us!
        </div>
        {w > 996 ? (
          <div className="mt-5 py-5" align="center">
            <img src={engageus}></img>
          </div>
        ) : (
          <div className="mt-5 py-5" align="center">
            <img src={engageus}  style={{width:"100%"}}></img>
          </div>
        )}
      </div>
    </div>
  );
}
export default EngageUs;
