import React from "react";
import Navbar from "./Navbar";
import WebsiteFooter from "./WebsiteFooter";

function PrivacyPolicy() {
  return (
    <div>
      <div style={{ backgroundColor: "#F5EDFC" }}>
        <Navbar />
        <div className="container mt-5 py-5">
          <div
            className=" mt-5 p-5"
            style={{
              fontSize: "2.9rem",
              fontWeight: "800",
              lineHeight: "52px",
            }}
            align="center"
          >
            Privacy policy
          </div>{" "}
        </div>
      </div>
      {/* <div className="container mt-5 py-5">
        <div style={{textalign:"left"}}>
            <span  style={{fontWeight:"700", fontSize:"1.2rem"}}>Table of content</span>
            <ol>
                <li>Lorem ipsum dolor sit</li>
                <li>Lorem ipsum dolor sit</li>
                <li>Lorem ipsum dolor sit</li>
                <li>Lorem ipsum dolor sit</li>
            </ol>
        </div>
        <ol className="mt-5">
          <li>
           <b>Lorem ipsum dolor sit:</b> 
            <span>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus
              viverra vitae congue eu consequat ac. Aliquam etiam erat velit
              scelerisque in. Cras pulvinar mattis nunc sed blandit libero
              volutpat sed. Sit amet massa vitae tortor condimentum lacinia
              quis. Bibendum enim facilisis gravida neque convallis. Netus et
              malesuada fames ac. Venenatis a condimen tum vitae sapien
              pellentesque habitant morbi tristique senectus. Risus viverra
              adipiscing at in tellus integer feugiat. Dolor sed viverra ipsum
              nunc aliquet bibendum enim facilisis gravida. Arcu non sodales
              neque sodales ut etiam sit amet. Hendrerit gravida rutrum quisque
              non tellus orci ac auctor augue. In mollis nunc sed id semper
              risus in hendrerit. Tincidunt nunc pulvinar sapien et ligula
              ullamcorper malesuada proin.
            </span>
          </li>
          <li className="mt-3">
           <b>Lorem ipsum dolor sit:</b> {" "}
            <span>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus
              viverra vitae congue eu consequat ac. Aliquam etiam erat velit
              scelerisque in. Cras pulvinar mattis nunc sed blandit libero
              volutpat sed. Sit amet massa vitae tortor condimentum lacinia
              quis. Bibendum enim facilisis gravida neque convallis. Netus et
              malesuada fames ac. Venenatis a condimen tum vitae sapien
              pellentesque habitant morbi tristique senectus. Risus viverra
              adipiscing at in tellus integer feugiat. Dolor sed viverra ipsum
              nunc aliquet bibendum enim facilisis gravida. Arcu non sodales
              neque sodales ut etiam sit amet. Hendrerit gravida rutrum quisque
              non tellus orci ac auctor augue. In mollis nunc sed id semper
              risus in hendrerit. Tincidunt nunc pulvinar sapien et ligula
              ullamcorper malesuada proin.
            </span>
          </li>
          <li className="mt-3">
           <b>Lorem ipsum dolor sit:</b> {" "}
            <span>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus
              viverra vitae congue eu consequat ac. Aliquam etiam erat velit
              scelerisque in. Cras pulvinar mattis nunc sed blandit libero
              volutpat sed. Sit amet massa vitae tortor condimentum lacinia
              quis. Bibendum enim facilisis gravida neque convallis. Netus et
              malesuada fames ac. Venenatis a condimen tum vitae sapien
              pellentesque habitant morbi tristique senectus. Risus viverra
              adipiscing at in tellus integer feugiat. Dolor sed viverra ipsum
              nunc aliquet bibendum enim facilisis gravida. Arcu non sodales
              neque sodales ut etiam sit amet. Hendrerit gravida rutrum quisque
              non tellus orci ac auctor augue. In mollis nunc sed id semper
              risus in hendrerit. Tincidunt nunc pulvinar sapien et ligula
              ullamcorper malesuada proin.
            </span>
          </li>
          <li className="mt-3">
           <b>Lorem ipsum dolor sit:</b> {" "}
            <span>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus
              viverra vitae congue eu consequat ac. Aliquam etiam erat velit
              scelerisque in. Cras pulvinar mattis nunc sed blandit libero
              volutpat sed. Sit amet massa vitae tortor condimentum lacinia
              quis. Bibendum enim facilisis gravida neque convallis. Netus et
              malesuada fames ac. Venenatis a condimen tum vitae sapien
              pellentesque habitant morbi tristique senectus. Risus viverra
              adipiscing at in tellus integer feugiat. Dolor sed viverra ipsum
              nunc aliquet bibendum enim facilisis gravida. Arcu non sodales
              neque sodales ut etiam sit amet. Hendrerit gravida rutrum quisque
              non tellus orci ac auctor augue. In mollis nunc sed id semper
              risus in hendrerit. Tincidunt nunc pulvinar sapien et ligula
              ullamcorper malesuada proin.
            </span>
            <div className="ps-5 mt-3" >
              <b>a. lorem ipsum: </b>Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua. Lacus viverra vitae congue eu consequat ac. Aliquam etiam
              erat velit scelerisque in. Cras pulvinar mattis nunc sed blandit
              libero volutpat sed. Sit amet massa vitae tortor condimentum
              lacinia quis. Bibendum enim facilisis gravida neque convallis.
              Netus et malesuada fames ac. Venenatis a condimen
            </div>
            <div className="ps-5 mt-3" >
              <b>b. lorem ipsum:</b> Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua. Lacus viverra vitae congue eu consequat ac. Aliquam etiam
              erat velit scelerisque in. Cras pulvinar mattis nunc sed blandit
              libero volutpat sed. Sit amet massa vitae tortor condimentum
              lacinia quis. Bibendum enim facilisis gravida neque convallis.
              Netus et malesuada fames ac. Venenatis a condimen
            </div>
          </li>
          <li className="mt-3">
           <b>Lorem ipsum dolor sit:</b> {" "}
            <span>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus
              viverra vitae congue eu consequat ac. Aliquam etiam erat velit
              scelerisque in. Cras pulvinar mattis nunc sed blandit libero
              volutpat sed. Sit amet massa vitae tortor condimentum lacinia
              quis. Bibendum enim facilisis gravida neque convallis. Netus et
              malesuada fames ac. Venenatis a condimen tum vitae sapien
              pellentesque habitant morbi tristique senectus. Risus viverra
              adipiscing at in tellus integer feugiat. Dolor sed viverra ipsum
              nunc aliquet bibendum enim facilisis gravida. Arcu non sodales
              neque sodales ut etiam sit amet. Hendrerit gravida rutrum quisque
              non tellus orci ac auctor augue. In mollis nunc sed id semper
              risus in hendrerit. Tincidunt nunc pulvinar sapien et ligula
              ullamcorper malesuada proin.
            </span>
          </li>
        </ol>
      </div> */}
       <div className="container mt-5 py-5">
        <div>
          <div>
            Talent RPO (hereinafter referred to as “Talent RPO” or “we” “us” or
            “our”) and its affiliates value our users' privacy concerns. This
            privacy statement is applicable to "you," our clients, prospects,
            business partners, candidates, website users, suppliers, and any
            other third parties we may work with or to whom we may provide our
            services, such as recruitment process outsourcing (RPO), executive
            search, and talent consultancy. This page represents information on
            our policies regarding the collection, use, and sharing of Personal
            Information from users of the website. By using our website, you
            consent to the collection and use of your information in accordance
            with this policy. The Personal Information we gather is used to
            maintain and enhance the website. Because we value your privacy, we
            advise you to read this privacy statement (the "Privacy Policy"),
            understand how we will use your Personal Information, and get in
            touch with us if you have any questions. We won't use or disclose
            your information in any other way than what is stated in this
            privacy statement.
          </div>
          <div className="mt-3">
            {" "}
            1. <b>Information Collection and Use:</b>
            <span>
              {" "}
              Our website will not automatically collect any personal
              information data of the visitors. It Depends on how you choose to
              interact with us and the services you use, different information
              is gathered. The majority of the time, the only way we will learn
              personally identifying information about you is if you choose to
              provide it to us. Examples of such information include your name,
              phone number, email address, and company name. We will use the
              data we gather to get in touch with or verify you. We gather both
              direct and indirect access to your personal data.
            </span>
          </div>
          <div className="ps-5 mt-3">
            <b>a. Direct Collection: </b>When you contact or engage with us, you
            choose to give us your personal information. Examples include
            filling out a form on our website, using our services, giving us
            services, interacting with us on social media, submitting your
            resume, attending an event we sponsor, interviewing with us, or
            engaging in another interaction. Your consent is always sought
            before processing or collecting your personal information.
          </div>
          <div className="ps-5 mt-3">
            <b>b. Indirect Collection:</b> As it relates to your job title,
            education, and employment, we may obtain your Personal Information
            from publicly accessible resources and databases, such as but not
            limited to social media websites like Facebook or LinkedIn,
            advertisements, public job boards, industry organisations, industry
            events, and contact databases.
          </div>
          <div className="mt-3">
            We may gather information from publicly or commercially available
            sources that we deem reputable in addition to the information we
            submit or access through social media networks. The information
            mentioned above may be kept on secure cloud-based servers that are
            off-limits to outsiders in TalentRPO's internal systems, which may
            include but are not limited to client relationship management (CRM),
            marketing automation software programmes, applicant tracking systems
            (ATS), and talent acquisition software platforms. These systems give
            our staff members access to and processing rights over such Personal
            Information, which they may only use for client service, company
            reporting and administration, statistical analysis, and service
            marketing at TalentRPO. We don't offer our mailing lists for sale to
            outside businesses. Except with your permission or as required by
            law, we will not release your Personal Information to third parties.
            Your personal information might be used by us to pitch our services
            to you directly. Please get in touch with us as described in the
            section below if you ever want us to stop using your information for
            any reason. As soon as it's practical, we'll stop using your
            personal information for these purposes.
          </div>
        </div>
        <div className="mt-3">
          <div>
            2. <b>Log Data: </b>
            <span>
              We would like to let you know that whenever you visit our website,
              we get information from your browser known as "Log Data" that we
              store and analyse. The Internet Protocol ("IP") address of your
              computer, the version of your browser, the pages of our website
              that you view, the time and date of your visit, the length of time
              spent on those pages, and other statistics may be included in this
              Log Data.
            </span>
          </div>
        </div>
        <div className="mt-3">
          <div>
            3. <b>Service Providers: </b>
            <span>
              You agree to the company as a whole receiving your personal
              information when you give it to a Talent RPO employee, like a
              recruiter. Your connection to TalentRPO will determine which third
              parties we disclose your personal information with to carry out
              tasks or provide services on our behalf, including but not limited
              to:
            </span>
          </div>
          <div className="ps-5 mt-3">
            <div> ● Storage providers who process & provide information;</div>
            <div>● Public authorities who submit a legitimate request;</div>
            <div>
              {" "}
              ● Prospective and current clients for whom we offer services.
            </div>
          </div>
          <br />
          However, We ensure they keep the information private and do not use it
          or reveal it for any other reason.
        </div>
        <div className="mt-3">
          <div>
            4. <b>Security: </b>
            <span>
              We appreciate your confidence in entrusting us with your personal
              information, so we make every effort to protect it through legally
              permissible measures. To guard the Personal Information we have
              under our control against unauthorised access, improper use,
              alteration, unlawful or accidental destruction, and unintentional
              loss, we have put in place technology, security policies, and
              methods. However, keep in mind that no form of electronic storage
              or communication via the internet is 100% secure and dependable,
              and we cannot guarantee its total security.
            </span>
          </div>
          <div className="mt-3">
            <div>
              5. <b>Links to Other Sites: </b>
              <span>
                Links to other websites may be found on our website. A
                third-party link will take you to that website if you click on
                it. A link from another unrelated, third-party website may
                direct you to our website. Keep in mind that TalentRPO does not
                run these external websites. Because of this, we strongly
                suggest that you read the privacy policies of these websites.
                Any other Internet site that is connected to this one must be
                accessed at the user's own risk. The content, privacy practices,
                or other policies of any third-party websites or services are
                beyond our control and responsibility. We don't mean to suggest
                sponsorship, ties to, or approval of other websites.
              </span>
            </div>
          </div>
          <div className="mt-3">
            <div>
              6. <b>Children's Privacy </b>
              <span>
                Children under 13 are not knowingly subject to our collection of
                personal information. If we learn that a child under 13 has
                given us Personal Information, we will remove it from our
                servers right away. Please get in touch with us if you are a
                parent or guardian and you are aware that your child has given
                us Personal Information so that we can take the required steps.
              </span>
            </div>
          </div>
          <div className="mt-3">
            <div>
              7. <b>Changes to This Privacy Policy: </b>
              <span>
                Our privacy policy may periodically be updated. As a result, we
                urge you to check this page frequently for updates. Any
                modifications will be communicated to you by posting the updated
                Privacy Policy on this page. Once they are posted on this
                website, the modifications take effect right away.
              </span>
            </div>
          </div>
          <div className="mt-3">
            <div>
              8. <b>Contact Us: </b>
              <span>
                Please don't hesitate to get in touch with us at the number or
                address listed above if you have any questions, comments, or
                concerns about our privacy policy. We value your feedback and
                suggestions!
              </span>
            </div>
          </div>
        </div>
       
      </div>
      <WebsiteFooter />
    </div>
  );
}
export default PrivacyPolicy;
