import React from "react";
import jasmine from "../Images/testimonial_jasmine.svg";
import jack from "../Images/testimonial_jack.svg";
import wednesday from "../Images/testimonial_wednesday.svg";
import "../Styles/Testimonial.css";

// const ReadMore = ({ children }) => {
//   const text = children;
//   const [isReadMore, setIsReadMore] = useState(true);
//   const toggleReadMore = () => {
//     setIsReadMore(!isReadMore);
//   };
//   return (
//     <p className="text">
//       {isReadMore ? text.slice(0, 150) : text}
//       <span style={{cursor:"pointer" ,color:"#245D51"}} onClick={toggleReadMore} className="reador-hidden"><b> {isReadMore ? "  ...readmore" : "show less"}</b>

//       </span>
//     </p>
//   );
// };

function Testimonials() {
  return (
    <div className="testimonials" id="findsenior">
      <div className="container">
        <div className="">
          <div className="mb-3"
            align="center"
            style={{
              fontSize: "46px",
              fontWeight: "800",
              lineHeight: "52px",
            }}
          >
            Testimonial
          </div>
        </div>

        <div className="content ">
          <div className="card p-3">
            <div className="d-flex">
              <div>
                <img src={jasmine}></img>
              </div>
              <div
                className="p-3 py-4"
                style={{
                  fontSize: "19px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                Jasmine
              </div>
            </div>
            <div
              className="mt-4 mb-5"
              style={{
                lineHeight: "28px",
              }}
            >
              <span
                style={{
                  color: "#C698EB",
                  fontSize: "46px",
                  fontWeight: "800",
                  lineHeight: "52px",
                }}
              >
                “
              </span>{" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              <span
                className=""
                style={{
                  color: "#C698EB",
                  fontSize: "46px",
                  fontWeight: "800",
                  position: "absolute",
                  bottom: "18%",
                  // lineHeight:"52px"
                }}
              >
                ”
              </span>
            </div>
          </div>
          <div className="card p-3">
            <div className="d-flex">
              <div>
                <img src={jack}></img>
              </div>
              <div
                className="p-3 py-4"
                style={{
                  fontSize: "19px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                Jack
              </div>
            </div>
            <div
              className="mt-4 mb-5"
              style={{
                lineHeight: "28px",
              }}
            >
              <span
                style={{
                  color: "#C698EB",
                  fontSize: "46px",
                  fontWeight: "800",
                  lineHeight: "52px",
                }}
              >
                “
              </span>{" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              <span
                className=""
                style={{
                  color: "#C698EB",
                  fontSize: "46px",
                  fontWeight: "800",
                  position: "absolute",
                  bottom: "18%",
                  // lineHeight:"52px"
                }}
              >
                ”
              </span>
            </div>
          </div>
          <div className="card p-3">
            <div className="d-flex">
              <div>
                <img src={wednesday}></img>
              </div>
              <div
                className="p-3 py-4"
                style={{
                  fontSize: "19px",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                Wednesday
              </div>
            </div>
            <div
              className="mt-4 mb-5"
              style={{
                lineHeight: "28px",
              }}
            >
              <span
                style={{
                  color: "#C698EB",
                  fontSize: "46px",
                  fontWeight: "800",
                  lineHeight: "52px",
                }}
              >
                “
              </span>{" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              <span
                className=""
                style={{
                  color: "#C698EB",
                  fontSize: "46px",
                  fontWeight: "800",
                  position: "absolute",
                  bottom: "18%",
                  // lineHeight:"52px"
                }}
              >
                ”
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
