import React from "react";
import chooseus_curve_img from "../Images/chooseus_curve_img.png";
function ChooseUs() {
  return (
    <div style={{ background: "black", color: "white" }}>
      <div className="container">
        <div className="row py-5">
          <div className="col-md-5 ">
            <div className="row mt-5 py-5">
              <div className="col-md-6">
                <div
                  style={{
                    border: "1px solid #9D4EDD",
                    borderRadius: "8px",
                    padding: "25px 10px",
                    boxSizing: "border-box",
                    background: "#1E1A20",
                    fontSize: "0.8rem",
                  }}
                >
                  Get trained with industry expert
                </div>
                <div
                  className="mt-4"
                  style={{
                    border: "1px solid #9D4EDD",
                    borderRadius: "8px",
                    padding: "25px 10px",
                    boxSizing: "border-box",
                    background: "#1E1A20",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  100+ clients
                </div>
              </div>
              <div className="col-md-6">
                <div
                  style={{
                    border: "1px solid #9D4EDD",
                    borderRadius: "8px",
                    padding: "25px 10px",
                    boxSizing: "border-box",
                    background: "#1E1A20",
                    fontSize: "0.8rem",
                  }}
                >
                  Real time project Training
                </div>
                <div
                  className="mt-4"
                  style={{
                    border: "1px solid #9D4EDD",
                    borderRadius: "8px",
                    padding: "25px 10px",
                    boxSizing: "border-box",
                    background: "#1E1A20",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  Course - End to End Recruitment
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 px-5">
            <div className="mt-5 py-5">
              <div
                style={{
                  fontSize: "46px",
                  fontWeight: "800",
                  lineHeight: "52px",
                }}
              >
                Why choose us?
              </div>
              <div
                className="mt-3"
                style={{
                  fontSize: "15px",
                  fontWeight: "400",
                  lineHeight: "28px",
                }}
              >
                With our dedicated team of recruiting specialists who can help
                your company in hiring talent. Our Talent RPO services are
                flexible, and our high-touch methodology makes it simple to
                scale them up or down in accordance with your needs.
              </div>
            </div>
          </div>
          <div className="col-md-1">
            <div>
              <img
                src={chooseus_curve_img}
                className="float-end"
                alt="right_img"
                style={{
                  position: "absolute",
                  right: "0%",
                  // height: "384px",
                  width: "120px",
                }}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChooseUs;
