import React from "react";
import wecare from "../Images/wecare.svg";
import weprioritize from "../Images/weprioritize.svg";
import wesupport from "../Images/wesupport.svg";
import wedeliver from "../Images/wedeliver.svg";
// import WeareBig from "../Images/weareFliprightBig.png";
// import WeareSmall from "../Images/weareFliprightSmall.png";
import weare_left_big from "../Images/weare_left_big.png";
import weare_left_small from "../Images/weare_left_small.png";
import weare_right_big from "../Images/weare_right_big.png";
import weare_right_small from "../Images/weare_right_small.png";
import "../Styles/WhoWeAre.css";
// import ScrollTop from "./ScrollButton";
export default function Whoweare() {
  let w = window.innerWidth;
  return (
    <div align="center">
      <div
        id="whoweare"
        className="container-fluid"
        style={{ background: "#1E1A20" }}
      >
        <div className=" text-white p-5 who_we_are_mobile_responsive">
          <div className="mt-5 container mt-3  p-0">
            <div className=" p-0 m-0">
              <div
                className="who_we_are_heading"
                style={{ fontSize: "0.8rem", fontWeight: "600" }}
              >
                Who we are
              </div>
              <div className="py-3 row ">
                <div className="col-md-1 ">
                  <div className="who_we_are_mobile">
                    <img alt="who_we_are_images" src={weare_left_big} />
                  </div>
                </div>

                <div className="col-md-10  p-5  professional_text">
                  <div
                    style={{
                      fontWeight: "800",
                      lineHeight: "52px",
                      fontSize: "2.2rem",
                    }}
                  >
                    Professionally managed by IT/Software Industry veterans
                    providing IT Recruitment Services <br /> to our customers
                    globally.
                    {w > 996 ? (
                      <div className="d-flex justify-content-between ">
                        <div>
                          <img src={weare_right_small}></img>
                        </div>
                        <div>
                          <img src={weare_left_small}></img>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}{" "}
                  </div>
                </div>

                <div className="col-md-1 ">
                  <div className="who_we_are_mobile">
                    <img alt="who_we_are_images" src={weare_right_big} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="  text-white  container">
          <div>
            <div className="d-md-flex who_we_are_box   container justify-content-between">
              <div className="who_we_are_none_tablet"></div>
              <div className="px-md-5 py-3 ">
                <div className="who_we_are_padding">
                  <img alt="who_we_are_images" src={wecare} />
                  <div
                    className="py-2"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "700",
                      lineHeight: "20px",
                    }}
                  >
                    We Care
                  </div>
                </div>
              </div>
              <div className=" px-md-5 py-3">
                <div className="who_we_are_padding">
                  <img alt="who_we_are_images" src={weprioritize} />
                  <div
                    className="py-2"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "700",
                      lineHeight: "20px",
                    }}
                  >
                    We Prioritize
                  </div>
                </div>
              </div>

              <div className="px-md-5 py-3 ">
                <div className="who_we_are_padding">
                  <img alt="who_we_are_images" src={wesupport} />
                  <div
                    className="py-2"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "700",
                      lineHeight: "20px",
                    }}
                  >
                    We Support
                  </div>
                </div>
              </div>
              <div className=" px-md-5 py-3">
                <div className="who_we_are_padding">
                  <img alt="who_we_are_images" src={wedeliver} />
                  <div
                    className="py-2"
                    style={{
                      fontSize: "0.8rem",
                      fontWeight: "700",
                      lineHeight: "20px",
                    }}
                  >
                    We Deliver
                  </div>
                </div>
              </div>
              <div className=""></div>
              {/* <div style={{ backgroundColor: "white" }}></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
