import React, { useRef, useEffect } from "react";
import our_offerings from "../Images/our_offerings.png";
import tick_img from "../Images/tick_img.svg";

import "../Styles/OurOfferings.css";
function OurOfferings() {
  const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "#our-offerings") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);
  let w = window.innerWidth;
  return (
    <div ref={myRef} id="our-offerings">
      <div className="container p-2">
        <div className="mt-5 row container">
          {w > 996 ? (
            <div className="col-lg-8 mt-md-5">
              <div
                className="our_offering_heading"
                style={{
                  lineHeight: "64px",
                  fontSize: "2.9rem",
                  fontWeight: "800",
                }}
              >
                No Obligation <br />
                Try For <span style={{ color: "#9D4EDD" }}>Free</span>
              </div>

              <div className="mt-5 row container list">
                <div className="mt-3 col-md-6 p-0" align="start">
                  <div className="d-flex align-items-center">
                    <div>
                      <img alt="required_img" src={tick_img} width="100%"></img>
                    </div>
                    <div
                      className="ps-2 py-1"
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        color: "#151216",
                        lineHeight: "28px",
                      }}
                    >
                       free trial
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div>
                      <img alt="required_img" src={tick_img} width="100%"></img>
                    </div>
                    <div
                      className="ps-2 py-1"
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        color: "#151216",
                        lineHeight: "28px",
                      }}
                    >
                      No Upfront Payment
                    </div>
                  </div>
                </div>
                <div className="mt-3 col-md-6 p-0">
                  {" "}
                  <div className="d-flex align-items-center">
                    <div>
                      <img alt="required_img" src={tick_img} width="100%"></img>
                    </div>
                    <div
                      className="ps-2"
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        color: "#151216",
                        lineHeight: "28px",
                      }}
                    >
                      Quick Onboarding Process
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div>
                      <img alt="required_img" src={tick_img} width="100%"></img>
                    </div>
                    <div
                      className="ps-2 py-1"
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        color: "#151216",
                        lineHeight: "28px",
                      }}
                    >
                      See the result to believe!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className=" mt-md-5">
              <div
                className="our_offering_heading"
                style={{
                  lineHeight: "64px",
                  fontSize: "3.2rem",
                  fontWeight: "800",
                }}
              >
                No Obligation <br />
                Try For <span style={{ color: "#9D4EDD" }}>Free</span>
              </div>

              <div className="mt-5  container list">
                <div className="mt-3 p-0" align="start">
                  <div className="d-flex align-items-center">
                    <div>
                      <img alt="required_img" src={tick_img} width="100%"></img>
                    </div>
                    <div
                      className="ps-2 py-1"
                      style={{
                        fontSize:"1.3rem",
                        fontWeight: "600",
                        color: "#151216",
                        lineHeight: "28px",
                      }}
                    >
                     free trial
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div>
                      <img alt="required_img" src={tick_img} width="100%"></img>
                    </div>
                    <div
                      className="ps-2 py-1"
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "600",
                        color: "#151216",
                        lineHeight: "28px",
                      }}
                    >
                      No Upfront Payment
                    </div>
                  </div>
                </div>
                <div className="mt-3 col-md-6 p-0">
                  {" "}
                  <div className="d-flex align-items-center">
                    <div>
                      <img alt="required_img" src={tick_img} width="100%"></img>
                    </div>
                    <div
                      className="ps-2"
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "600",
                        color: "#151216",
                        lineHeight: "28px",
                      }}
                    >
                      Quick Onboarding Process
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <div>
                      <img alt="required_img" src={tick_img} width="100%"></img>
                    </div>
                    <div
                      className="ps-2 py-1"
                      style={{
                        fontSize: "1.3rem",
                        fontWeight: "600",
                        color: "#151216",
                        lineHeight: "28px",
                      }}
                    >
                      See the result to believe!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {w > 900 ? (
            <div className="col-lg-4 py-5" align="end">
              <img alt="required_img" src={our_offerings}></img>
            </div>
          ) : (
            <div className="col-lg-4 py-5" align="center">
              <img
                alt="required_img"
                src={our_offerings}
                className="our_process_image"
                width="60%"
              ></img>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default OurOfferings;
