import React from "react";
import features_girl_img from "../Images/features_girl_img.png";
import job_guarentee from "../Images/job_guarentee.svg";
import loan_emi_img from "../Images/loan_emi_img.svg";
import live_project_img from "../Images/live_project_img.svg";
function CourseFeatures() {
  return (
    <div>
      <div className=" container">
        <div className="row">
          <div className="col-md-7 py-5">
            <div className="px-3">
              <div
                style={{
                  fontSize: "46px",
                  fontWeight: "800",
                  lineHeight: "52px",
                  color: "#151216",
                }}
              >
                Course Features
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  fontWeight: "400",
                  lineHeight: "28px",
                }}
              >
                We provide comprehensive, individualised Recruitment Process
                Outsourcing services <br />
                that are customized to your organization's unique hiring
                requirements
              </div>
              <div className="mt-3">
                <img src={features_girl_img} width="100%" alt="girl_img"></img>
              </div>
            </div>
          </div>
          <div className="col-md-4  py-5">
            <div className="px-3 ">
              <div className="d-flex ">
                <div>
                  <img src={job_guarentee} alt="guarentee_img"></img>
                </div>

                <div className="ps-3 ">
                  {" "}
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "23px",
                      lineHeight: "32px",
                    }}
                  >
                    100% Job Guarenteed{" "}
                  </div>
                  <div style={{fontSize:"14px", fontWeight:"400", lineHeight:"20px", color:"#151216"}}> Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                  do eiusmod {" "}</div>
                 
                </div>
              </div>
            </div>
            <div className="px-3 mt-5">
              <div className="d-flex py-2">
                <div>
                  <img src={loan_emi_img} alt="emi_img"></img>
                </div>

                <div className="ps-3">
                  {" "}
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "23px",
                      lineHeight: "32px",
                    }}
                  >
                    Loan and EMI Available{" "}
                  </div>
                  <div style={{fontSize:"14px", fontWeight:"400", lineHeight:"20px", color:"#151216"}}> Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                  do eiusmod {" "}</div>
                 
                </div>
              </div>
            </div>
            <div className="px-3 mt-5">
              <div className="d-flex py-2">
                <div>
                  <img src={live_project_img} alt="live_project_img"></img>
                </div>

                <div className="ps-3">
                  {" "}
                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "23px",
                      lineHeight: "32px",
                    }}
                  >
                    Live Project training{" "}
                  </div>
                  <div style={{fontSize:"14px", fontWeight:"400", lineHeight:"20px", color:"#151216"}}> Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed
                  do eiusmod {" "}</div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CourseFeatures;
