import React from "react";
import Located from "./Located";
import Navbar from "./Navbar";
import WebsiteFooter from "./WebsiteFooter";

function Locations(){
    return(
        <div>
        <div style={{ background: "#1E1A20" }}>
          <Navbar />
      
        </div>
        <div className="container py-5 " align="center">
          <Located />
        </div>{" "}
        <WebsiteFooter />
      </div>
    )
}

export default Locations;