import React from "react";
import Navbar from "./Navbar";

import WebsiteFooter from "./WebsiteFooter";

function CookiesPolicy() {
  return (
    <div>
      <div style={{ backgroundColor: "#F5EDFC" }}>
        <Navbar />
        <div className="container mt-5 py-5">
          <div
            className=" mt-5 p-5"
            style={{
              fontSize: "2.9rem",
              fontWeight: "800",
              lineHeight: "52px",
            }}
            align="center"
          >
            Cookies policy
          </div>{" "}
        </div>
      </div>
      <div className="container mt-5 py-5">
        {/* <div style={{textalign:"left"}}>
            <span  style={{fontWeight:"700", fontSize:"1.2rem"}}>Table of content</span>
            <ol>
                <li>Lorem ipsum dolor sit</li>
                <li>Lorem ipsum dolor sit</li>
                <li>Lorem ipsum dolor sit</li>
                <li>Lorem ipsum dolor sit</li>
            </ol>
        </div>
        <ol className="mt-5">
          <li>
           <b>Lorem ipsum dolor sit:</b> 
            <span>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus
              viverra vitae congue eu consequat ac. Aliquam etiam erat velit
              scelerisque in. Cras pulvinar mattis nunc sed blandit libero
              volutpat sed. Sit amet massa vitae tortor condimentum lacinia
              quis. Bibendum enim facilisis gravida neque convallis. Netus et
              malesuada fames ac. Venenatis a condimen tum vitae sapien
              pellentesque habitant morbi tristique senectus. Risus viverra
              adipiscing at in tellus integer feugiat. Dolor sed viverra ipsum
              nunc aliquet bibendum enim facilisis gravida. Arcu non sodales
              neque sodales ut etiam sit amet. Hendrerit gravida rutrum quisque
              non tellus orci ac auctor augue. In mollis nunc sed id semper
              risus in hendrerit. Tincidunt nunc pulvinar sapien et ligula
              ullamcorper malesuada proin.
            </span>
          </li>
          <li className="mt-3">
           <b>Lorem ipsum dolor sit:</b> {" "}
            <span>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus
              viverra vitae congue eu consequat ac. Aliquam etiam erat velit
              scelerisque in. Cras pulvinar mattis nunc sed blandit libero
              volutpat sed. Sit amet massa vitae tortor condimentum lacinia
              quis. Bibendum enim facilisis gravida neque convallis. Netus et
              malesuada fames ac. Venenatis a condimen tum vitae sapien
              pellentesque habitant morbi tristique senectus. Risus viverra
              adipiscing at in tellus integer feugiat. Dolor sed viverra ipsum
              nunc aliquet bibendum enim facilisis gravida. Arcu non sodales
              neque sodales ut etiam sit amet. Hendrerit gravida rutrum quisque
              non tellus orci ac auctor augue. In mollis nunc sed id semper
              risus in hendrerit. Tincidunt nunc pulvinar sapien et ligula
              ullamcorper malesuada proin.
            </span>
          </li>
          <li className="mt-3">
           <b>Lorem ipsum dolor sit:</b> {" "}
            <span>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus
              viverra vitae congue eu consequat ac. Aliquam etiam erat velit
              scelerisque in. Cras pulvinar mattis nunc sed blandit libero
              volutpat sed. Sit amet massa vitae tortor condimentum lacinia
              quis. Bibendum enim facilisis gravida neque convallis. Netus et
              malesuada fames ac. Venenatis a condimen tum vitae sapien
              pellentesque habitant morbi tristique senectus. Risus viverra
              adipiscing at in tellus integer feugiat. Dolor sed viverra ipsum
              nunc aliquet bibendum enim facilisis gravida. Arcu non sodales
              neque sodales ut etiam sit amet. Hendrerit gravida rutrum quisque
              non tellus orci ac auctor augue. In mollis nunc sed id semper
              risus in hendrerit. Tincidunt nunc pulvinar sapien et ligula
              ullamcorper malesuada proin.
            </span>
          </li>
          <li className="mt-3">
           <b>Lorem ipsum dolor sit:</b> {" "}
            <span>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus
              viverra vitae congue eu consequat ac. Aliquam etiam erat velit
              scelerisque in. Cras pulvinar mattis nunc sed blandit libero
              volutpat sed. Sit amet massa vitae tortor condimentum lacinia
              quis. Bibendum enim facilisis gravida neque convallis. Netus et
              malesuada fames ac. Venenatis a condimen tum vitae sapien
              pellentesque habitant morbi tristique senectus. Risus viverra
              adipiscing at in tellus integer feugiat. Dolor sed viverra ipsum
              nunc aliquet bibendum enim facilisis gravida. Arcu non sodales
              neque sodales ut etiam sit amet. Hendrerit gravida rutrum quisque
              non tellus orci ac auctor augue. In mollis nunc sed id semper
              risus in hendrerit. Tincidunt nunc pulvinar sapien et ligula
              ullamcorper malesuada proin.
            </span>
            <div className="ps-5 mt-3" >
              <b>a. lorem ipsum: </b>Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua. Lacus viverra vitae congue eu consequat ac. Aliquam etiam
              erat velit scelerisque in. Cras pulvinar mattis nunc sed blandit
              libero volutpat sed. Sit amet massa vitae tortor condimentum
              lacinia quis. Bibendum enim facilisis gravida neque convallis.
              Netus et malesuada fames ac. Venenatis a condimen
            </div>
            <div className="ps-5 mt-3" >
              <b>b. lorem ipsum:</b> Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua. Lacus viverra vitae congue eu consequat ac. Aliquam etiam
              erat velit scelerisque in. Cras pulvinar mattis nunc sed blandit
              libero volutpat sed. Sit amet massa vitae tortor condimentum
              lacinia quis. Bibendum enim facilisis gravida neque convallis.
              Netus et malesuada fames ac. Venenatis a condimen
            </div>
          </li>
          <li className="mt-3">
           <b>Lorem ipsum dolor sit:</b> {" "}
            <span>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lacus
              viverra vitae congue eu consequat ac. Aliquam etiam erat velit
              scelerisque in. Cras pulvinar mattis nunc sed blandit libero
              volutpat sed. Sit amet massa vitae tortor condimentum lacinia
              quis. Bibendum enim facilisis gravida neque convallis. Netus et
              malesuada fames ac. Venenatis a condimen tum vitae sapien
              pellentesque habitant morbi tristique senectus. Risus viverra
              adipiscing at in tellus integer feugiat. Dolor sed viverra ipsum
              nunc aliquet bibendum enim facilisis gravida. Arcu non sodales
              neque sodales ut etiam sit amet. Hendrerit gravida rutrum quisque
              non tellus orci ac auctor augue. In mollis nunc sed id semper
              risus in hendrerit. Tincidunt nunc pulvinar sapien et ligula
              ullamcorper malesuada proin.
            </span>
          </li>
        </ol> */}
        <div className="">
          Cookies are just little data files that are frequently utilized as
          anonymous unique identifiers. These are downloaded to your computer's
          hard drive from the website you visited and transmitted to your
          browser.
        </div>
        <div className="mt-3">
          These cookies are used by our website to gather data and enhance it.
          To better understand how you interact with our information and to
          enhance your visit to our website, we use cookies.
        </div>
        <div className="mt-3">
          Cookies won't be used by us to gather your personally identifying
          information. However, you can choose to block cookies set by our
          website or the websites of any third-party suppliers if you so desire
          by modifying your browser settings. Please be aware that the majority
          of browsers accept cookies by default, so you might need to manually
          delete or block cookies if you don't want them to be used.
        </div>
        <div className="mt-3">
          You can still use our website if you choose to block our cookies, but
          you might not be able to use all of its features
        </div>
      </div>
      <WebsiteFooter />
    </div>
  );
}
export default CookiesPolicy;
