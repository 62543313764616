import React, { useState, useEffect } from "react";
// import get_started_bg from "../Images/get_started_bg.png";
import "../Styles/Hero.css";
// import rpo_logo from "../Images/rpo_logo.png";

function Hero() {
  let w = window.innerWidth;

  return (
    <div>
      <div style={{ fontFamily: "Inter" }}>
        <div>
          {/* <img 
            src={get_started_bg}
            style={{ width: "100%", height: "100vh ", objectFit: "cover" }}
            alt="bg_img"
          ></img> */}
          <div
          // style={{
          //   backgroundImage: url("../Images/get_started_bg_png"),
          //   padding: "20%",
          // }}
          >
            <div className="bg_image">
              <div className="container"  align="center"> 
                {w > 992 ? (
                  <div data-aos="fade-up" data-aos-duration="1000"
                    style={{
                      fontSize: "46px",
                      fontWeight: "900",
                      lineHeight: "56px",
                    }}
                  >
                    {" "}
                    Am I a victim of{" "}
                  </div>
                ) : (
                  <div data-aos="fade-up" data-aos-duration="1000"
                    style={{
                      fontSize: "36px",
                      fontWeight: "900",
                      // lineHeight: "6px",
                    }}
                  >
                    {" "}
                    Am I a victim of{" "}
                  </div>
                )}
                {w > 992 ? (
                  <div data-aos="fade-up" data-aos-duration="1000"
                    style={{
                      color: "#FF7900",
                      lineHeight: "68px",
                      fontSize: "57px",
                      textAlign: "center",
                      fontWeight: "900",
                    }}
                  >
                    GLOBAL RESIGNATION?
                  </div>
                ) : (
                  <div data-aos="fade-up" data-aos-duration="1000"
                    style={{
                      color: "#FF7900",
                      lineHeight: "48px",
                      fontSize: "45px",
                      textAlign: "center",
                      fontWeight: "900",
                    }}
                  >
                    GLOBAL RESIGNATION?
                  </div>
                )}

                <br />
                {w > 992 ? (
                  <div  data-aos="fade-up" data-aos-duration="1000"
                    className="nightmares_text"
                    style={{
                      color: "white",
                      fontSize: "50px",
                      lineHeight: "68px",
                      fontWeight: "900",
                    }}
                  >
                    {" "}
                    Having Challenges Finding The Right Talents?
                  </div>
                ) : (
                  <div data-aos="fade-up" data-aos-duration="1000"
                    className=""
                    style={{
                      color: "white",
                      fontSize: "33px",
                      lineHeight: "44px",
                      fontWeight: "900",
                    }}
                  >
                    {" "}
                    Having Challenges Finding The Right Talents?
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
