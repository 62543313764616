import React from "react";
import chennai from "../Images/chennai.png";
import bangalore from "../Images/bangalore.png";
// import jaipur from "../Images/jaipur.png";
// import kerala from "../Images/kerala.png";
// import vizag from "../Images/vizag.png";
// import goa from "../Images/goa.png";
import "../Styles/Located.css";
function Located() {
  let w = window.innerWidth;
  return (
    <div className="mt-5 py-5">
      <div className="container ">
        <div
          className="located_heading"
          style={{
            color: "#151216",
            lineHeight: "52px",
            fontSize: "2.9rem",
            fontWeight: "800",
          }}
        >
          Locations
        </div>
        <div className=" row p-5">
          {w > 991 ? (
            <div className="col-lg-6 col-md-6 col-12">
              <div className="d-flex p-3" style={{ background: "#F5EDFC4D" }}>
                <div className="py-2">
                  <img alt="location_images" src={chennai}></img>
                </div>
                <div className="ps-2 " style={{ textAlign: "left" }}>
                  <b> Chennai</b>
                  <div style={{ fontSize: "0.7rem" }}>
                    81B, 2nd Main Road,
                    <br />
                    5th Floor, Prince Info Park, B-Block, Ambattur Industrial
                    Estate
                    <br />
                    Chennai - 600058
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-lg-6 col-md-6 col-12 ps-3 mt-lg-5">
              <div style={{ background: "#F5EDFC4D" }}>
                <div className="d-lg-flex p-3">
                  <div>
                    <img alt="location_images" src={chennai}></img>
                  </div>
                  <div className="ps-2">
                    <b>Chennai</b>
                    <div style={{ fontSize: "0.7rem" }}>
                      Prince Info Park B-Block,
                      <br /> 5th Floor 81B, 2nd Main Road Ambattur Industrial
                      Estate Chennai - 600058
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {w > 992 ? (
            <div className="col-lg-6 col-md-6 col-12 ps-3 ">
              <div>
                <div
                  className="d-flex p-3"
                  style={{ background: "#F5EDFC4D", textAlign: "left" }}
                >
                  <div className="py-2">
                    <img alt="location_images" src={bangalore}></img>
                  </div>
                  <div className="ps-2 py-3">
                    <b>New York</b>
                    <div style={{ fontSize: "0.7rem" }}>
                      99, Wall Street STE 4011 New York City,
                      <br />
                      NY 10005(USA).
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-lg-6 col-md-6 col-12 ps-3 mt-lg-5">
              <div style={{ background: "#F5EDFC4D" }}>
                <div className="d-lg-flex p-3">
                  <div>
                    <img alt="location_images" src={bangalore}></img>
                  </div>
                  <div className="ps-2">
                    <b>New York</b>
                    <div style={{ fontSize: "0.7rem" }}>
                      99, Wall Street STE 4011
                      <br /> New York City,
                      <br />
                      NY 10005(USA)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {w > 992 ? (
            <div className="col-lg-6 col-md-6 col-12 ps-3 mt-5">
              <div>
                <div
                  className="d-flex p-3"
                  style={{ background: "#F5EDFC4D", textAlign: "left" }}
                >
                  <div className="py-2">
                    <img
                      style={{
                        width: "5rem",
                        height: "5rem",
                        borderRadius: "5rem",
                      }}
                      alt="location_images"
                      src="https://deih43ym53wif.cloudfront.net/large_bangalore-india-shutterstock_662210488_ac0dd8543d.jpeg"
                    ></img>
                  </div>
                  <div className="ps-2 py-3">
                    <b>Bangalore</b>
                    <div style={{ fontSize: "0.7rem" }}>
                      No.1 Pine Hurst, 100 Feet Ring Rd, BTM 2nd Stage,
                      <br /> BTM Layout, Bengaluru, Karnataka 560076
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-lg-6 col-md-6 col-12 ps-3 mt-lg-5">
              <div style={{ background: "#F5EDFC4D" }}>
                <div className="d-lg-flex p-3">
                  <div>
                    <img
                      style={{
                        width: "3rem",
                        height: "3rem",
                        borderRadius: "3rem",
                      }}
                      alt="location_images"
                      src="https://deih43ym53wif.cloudfront.net/large_bangalore-india-shutterstock_662210488_ac0dd8543d.jpeg"
                    ></img>
                  </div>
                  <div className="ps-2">
                    <b>Bangalore</b>
                    <div style={{ fontSize: "0.7rem" }}>
                      No.1 Pine Hurst, 100 Feet Ring Rd, BTM 2nd Stage,
                      <br /> BTM Layout, Bengaluru, Karnataka 560076
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Located;
