import ImageViewer from "react-simple-image-viewer";
import { useState, useCallback } from "react";
import infra_img1 from "../Images/infra_img1.png";
import infra_img2 from "../Images/infra_img2.png";
import infra_img3 from "../Images/infra_img3.png";
import Navbar from "./Navbar";
import WebsiteFooter from "./WebsiteFooter";
function ZoomImg() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    infra_img1,
    infra_img2,
    infra_img3,
    // infra_img1,
    // infra_img2,
    // infra_img3,
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
    document.body.style.overflow = "hidden";
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
    document.body.style.overflow = "unset";
  };
 let w  = window.innerWidth;
  return (
    <>
      {" "}
      <div>
        <div style={{ background: "#1E1A20" }} className="p-5">
          <Navbar />
          <div className="p-5  container">
            <div align="center"  style={{ color: "white" }}>
              {
                w > 992 ? <div
                className="mt-5"
                style={{
                  fontSize: "3rem",
                  fontWeight: "800",
                  lineHeight: "60px",
                }}
              >
                Our Infrastructures
              </div> : <div align="center"
                className="mt-5"
                style={{
                  fontSize: "2rem",
                  fontWeight: "800",
                  textAlign:"center",
                  alignContent:"center",
                  lineHeight: "40px",
                }}
              >
                Our Infrastructures
              </div>
              }
             
              <div
                className=" py-5"
                style={{
                  color: "#A3A1A4",
                  lineHeight: "32px",
                  fontSize: "1.3rem",
                }}
                align="center"
              >
                We strive to become the best RPO company by building our
                client’s trust with our exceptional performance. Our team of
                highly professional recruiters understand all your hiring needs
                and always ready to exceed your expectations. Delivering only
                high quality candidates on time, is our utmost priority.
              </div>
            </div>
          </div>
        </div>
        <div className="container" align="center">
          <div className="row mt-5 py-5">
            {images.map((inf_imag, index) => {
              return (
                <div className="col-md-4">
                  <div className="p-1">
                    <img 
                      src={inf_imag}
                      onClick={() => openImageViewer(index)}
                      width="100%"
                      style={{cursor:"pointer"}}
                      //   key={index}
                      //   style={{ margin: "2px" }}
                      alt="open_img"
                    />{" "}
                    
                    {isViewerOpen && (
                      <ImageViewer
                        src={images}
                        currentIndex={currentImage}
                        onClose={closeImageViewer}
                        disableScroll={true}
                        color="white"
                        backgroundStyle={{
                          backgroundColor: "rgba(0,0,0,0.3)",
                        }}
                        closeOnClickOutside={true}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>{" "}
        <WebsiteFooter />
      </div>
    </>
  );
}

export default ZoomImg;
