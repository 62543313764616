import React from "react";
import statement6 from "../Images/statement6.png";
function Statement6() {
  let w = window.innerWidth;
  return (
    <div style={{ background: "#121014" }}  >
      <div
        className="py-5 container text-white"
        style={{ fontFamily: "Inter" }}
      >
        <div className="row">
          {w > 992 ? (
            <div className="col-md-7">
              <img
                src={statement6}
                alt="insufficient"
                style={{ width: "100%", objectFit: "cover" }}
              ></img>
            </div>
          ) : (
            <div className="col-md-5 " data-aos="fade-up" data-aos-duration="1000">
              <div className="">
                <div style={{ fontSize: "0.8rem" }}>My Challenge?</div>

                {w > 992 ? (
                  <div className="mt-3"
                    style={{
                      fontSize: "4.2rem",
                      fontWeight: "700",
                      lineHeight: "86px",
                    }}
                  >
                    <div>High cost</div>
                    <div
                      style={{
                        fontSize: "4.5rem",
                        fontWeight: "800",
                        color: "#FF7900",
                      }}
                    >
                      Business
                      <div
                        style={{
                          fontSize: "4.5rem",
                          fontWeight: "800",
                          color: "#FF7900",
                        }}
                      >
                        Impact{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className=""
                    style={{
                      fontSize: "3.2rem",
                      fontWeight: "700",
                      lineHeight: "60px",
                    }}
                  >
                    <div>High cost</div>
                    <div
                      style={{
                        fontSize: "3.5rem",
                        fontWeight: "800",
                        color: "#FF7900",
                      }}
                    >
                      Business
                      <div
                        style={{
                          fontSize: "3.5rem",
                          fontWeight: "800",
                          color: "#FF7900",
                        }}
                      >
                        Impact{" "}
                      </div>
                    </div>
                  </div>
                )}
                {w > 992 ? (
                  <div data-aos="fade-up" data-aos-duration="1000"
                    style={{ 
                      background: "#9D4EDD",
                      fontSize: "1.1rem",
                      fontWeight: "700",
                    }}
                    className="p-2"
                  >
                    <div className="ps-2 p-2">
                      <span style={{ color: "#FF7900" }}> ● </span>High Risk due
                      to poor quality IT Professionals
                    </div>
                    <div className="ps-2 p-2">
                      <span style={{ color: "#FF7900" }}> ● </span>Increase in
                      cost of the projects
                    </div>
                    <div className="ps-2 p-2">
                      <span style={{ color: "#FF7900" }}> ● </span>Delay in
                      Projects deliverables
                    </div>
                    <div className="ps-2 p-2">
                      <span style={{ color: "#FF7900" }}> ● </span>Hitting the
                      bottom line
                    </div>
                  </div>
                ) : (
                  <div data-aos="fade-up" data-aos-duration="1000"
                    style={{
                      background: "#9D4EDD",
                      fontSize: "1.1rem",
                      fontWeight: "700",
                    }}
                    className="p-2 mt-3"
                  >
                    <div className="ps-2 p-2" style={{ fontSize: "0.8rem" }}>
                      <span style={{ color: "#FF7900" }}> ● </span>High Risk due
                      to poor quality IT Professionals
                    </div>
                    <div className="ps-2 p-2" style={{ fontSize: "0.8rem" }}>
                      <span style={{ color: "#FF7900" }}> ● </span>Increase in
                      cost of the projects
                    </div>
                    <div className="ps-2 p-2" style={{ fontSize: "0.8rem" }}>
                      <span style={{ color: "#FF7900" }}> ● </span>Delay in
                      Projects deliverables
                    </div>
                    <div className="ps-2 p-2" style={{ fontSize: "0.8rem" }}>
                      <span style={{ color: "#FF7900" }}> ● </span>Hitting the
                      bottom line
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {w > 992 ? (
            <div className="col-md-5 mt-5 py-5" data-aos="fade-up" data-aos-duration="1000">
              <div className="mt-5">
                <div style={{ fontSize: "0.8rem" }}>My Challenge?</div>

                {w > 992 ? (
                  <div
                    style={{
                      fontSize: "4.2rem",
                      fontWeight: "700",
                      lineHeight: "86px",
                    }}
                  >
                    <div>High cost</div>
                    <div
                      style={{
                        fontSize: "4.5rem",
                        fontWeight: "800",
                        color: "#FF7900",
                      }}
                    >
                      Business
                      <div
                        style={{
                          fontSize: "4.5rem",
                          fontWeight: "800",
                          color: "#FF7900",
                        }}
                      >
                        Impact{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="mt-3 mb-3"
                    style={{
                      fontSize: "3.2rem",
                      fontWeight: "700",
                      lineHeight: "60px",
                    }}
                  >
                    <div>High cost</div>
                    <div
                      style={{
                        fontSize: "3.3rem",
                        fontWeight: "800",
                        color: "#FF7900",
                      }}
                    >
                      Business
                      <div className="mb-3"
                        style={{
                          fontSize: "3.3rem",
                          fontWeight: "800",
                          color: "#FF7900",
                        }}
                      >
                        Impact{" "}
                      </div>
                    </div><br />
                  </div>
                )}
                {w > 992 ? (
                  <div data-aos="fade-up" data-aos-duration="1000"
                    style={{
                      background: "#9D4EDD",
                      fontSize: "1.1rem",
                      fontWeight: "700",
                    }}
                    className="p-2 mt-3"
                  >
                    <div className="ps-2 p-2">
                      <span style={{ color: "#FF7900" }}> ● </span>High Risk due
                      to poor quality IT Professionals
                    </div>
                    <div className="ps-2 p-2">
                      <span style={{ color: "#FF7900" }}> ● </span>Increase in
                      cost of the projects
                    </div>
                    <div className="ps-2 p-2">
                      <span style={{ color: "#FF7900" }}> ● </span>Delay in
                      Projects deliverables
                    </div>
                    <div className="ps-2 p-2">
                      <span style={{ color: "#FF7900" }}> ● </span>Hitting the
                      bottom line
                    </div>
                  </div>
                ) : (
                  <div className="mt-5" data-aos="fade-up" data-aos-duration="1000"
                    style={{
                      // background: "blue",
                      fontSize: "1.1rem",
                      fontWeight: "700",
                    }}
                    
                  >
                    <div className="ps-2 p-2" style={{ fontSize: "0.8rem" }}>
                      <span style={{ color: "#FF7900" }}> ● </span>High Risk due
                      to poor quality IT Professionals
                    </div>
                    <div className="ps-2 p-2" style={{ fontSize: "0.8rem" }}>
                      <span style={{ color: "#FF7900" }}> ● </span>Increase in
                      cost of the projects
                    </div>
                    <div className="ps-2 p-2" style={{ fontSize: "0.8rem" }}>
                      <span style={{ color: "#FF7900" }}> ● </span>Delay in
                      Projects deliverables
                    </div>
                    <div className="ps-2 p-2" style={{ fontSize: "0.8rem" }}>
                      <span style={{ color: "#FF7900" }}> ● </span>Hitting the
                      bottom line
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="mt-5 col-md-7">
              <img
                src={statement6}
                alt="insufficient"
                style={{ width: "100%", objectFit: "cover" }}
              ></img>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Statement6;
