import React from "react";
import scarcity_side_img from "../Images/scarcity_side_img.png";
import "../Styles/Statement1.css";
function Statement1() {
  let w = window.innerWidth;
  return (
    <div style={{ background: "#121014" }}  >
      <div
        className="py-5 container text-white"
        style={{ fontFamily: "Inter" }}
      >
        <div className="row mt-3 ">
          <div className="py-5 col-md-6" >
           
            <br />
            {w > 992 ? (
              <div data-aos="fade-up" data-aos-duration="1000">
                <div className="mt-3" style={{
                  color: "#FFFFFF",
                  fontSize: "0.8rem",
                  lineHeight: "18px",
                }}>My Challenge?</div>
              <div
                style={{
                  fontSize: "4.3rem",
                  fontWeight: "800",
                  lineHeight: "86px",
                }}
              >
                <span style={{ color: "#FF7900" }}>Scarcity</span> of Talents
              </div></div>
            ) : (
              <div data-aos="fade-up" data-aos-duration="1000">
              <div className="mt-3" style={{
                color: "#FFFFFF",
                fontSize: "0.8rem",
                lineHeight: "18px",
              }}>My Challenge?</div>
              <div
                style={{
                  fontSize: "3.3rem",
                  fontWeight: "800",
                  lineHeight: "56px",
                }}
              >
                <span style={{ color: "#FF7900" }}>Scarcity</span> of Talents
              </div></div>
            )}
<div data-aos="fade-up" data-aos-duration="1000"><div className="d-flex mt-4 " >
              <div
                className="mt-2 mb-4"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#9D4EDD",
                  lineHeight: "24px",
                }}
              ></div>
              <div className="ps-2">Unqualified Candidates</div>
            </div>
            <div className="d-flex mt-3">
              <div
                className="mt-2 mb-4"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#9D4EDD",
                  lineHeight: "24px",
                }}
              ></div><br />
              <div className="ps-2">Moonlighting</div>
            </div>
            <div className="d-flex mt-3">
              <div
                className="mt-2"
                style={{
                  width: "20px",
                  height: "8px",
                  background: "#9D4EDD",
                  lineHeight: "24px",
                }}
              ></div>
              <div className="ps-2">Lack of Commitment</div>
            </div></div>
            
          </div>
          {w > 992 ? (
            <div className="col-md-6 statement1_img">
              <div>
                <img 
                  src={scarcity_side_img}
                  alt="scarcity"
                  style={{ width: "100%" }}
                ></img>
              </div>
            </div>
          ) : (
            <div align="center">
              <img
                src={scarcity_side_img}
                alt="scarcity"
                align="center"
                style={{ width: "90%" }}
              ></img>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Statement1;
