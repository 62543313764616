import React, { useState, useEffect, useRef } from "react";
import Endtoend from "../Images/Endtoend.svg";
import Ondemand from "../Images/Ondemand.svg";
import Sourcing from "../Images/Sourcing.svg";
import "../Styles/OurServices.css";
import DedicatedRecruiter from "../Images/DedicatedRecruiter.svg";
import ResearchSpecialist from "../Images/ResearchSpecialist.svg";

const ReadMore = ({ props, children }) => {
  const text = children;

  let [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
    props.setRead(!isReadMore);
  };
  return (
    <div className="text">
      {isReadMore && text ? text.toString().slice(0, 130) : text}
      <span
        style={{ cursor: "pointer", color: "black" }}
        onClick={toggleReadMore}
        className="reador-hidden"
      >
        <b> {isReadMore ? "  ...read more" : "show less"}</b>
      </span>
    </div>
  );
};

export default function Services() {
  const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === "#services") {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(), []);
  const [setRead] = useState(true);
  let w = window.innerWidth;

  return (
    <div
      className="mt-5 py-5 container service_mobile"
      id="services"
      ref={myRef}
    >
      <div align="center" className="p-5 service_mobile_responsive">
        <div>
          <div style={{ display: "none" }}>
            <ReadMore setRead={setRead} />
          </div>

          <div
            className="our_service_mobile"
            style={{
              lineHeight: "52px",
              fontSize: "2.9rem",
              fontWeight: "800",
            }}
          >
            Managed IT Recruitment Service
          </div>
        </div>
      </div>
      {w > 1075 ? (
        <div className="row container justify-content-center ">
          <div className="col-md-4  ">
            <div
              className="p-4 my-3 rounded"
              style={{
                borderTop: "6px solid #D7B6F1",
                background: "#F5EDFC80",
                // width: "22rem",
                // height: "18rem",
              }}
            >
              <div className="py-1 mt-3">
                <img alt="required_img" src={Endtoend} />
              </div>
              <div className="py-4">
                <div
                  style={{
                    fontSize: "1.3rem",
                    color: "#151216s",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                >
                  End-to-End <br />Recruitment Management
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4  ">
            <div
              className="p-4 my-3 rounded"
              style={{
                borderTop: "6px solid #D7B6F1",
                background: "#F5EDFC80",
                // width: "22rem",
                // height: "18rem",
              }}
            >
              <div className="py-1 mt-3">
                <img alt="required_img" src={Ondemand} />
              </div>
              <div className="py-4">
                <div
                  style={{
                    fontSize: "1.3rem",
                    color: "#151216s",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                >
                  OnDemand <br />Recruitment services
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="p-4 
              my-3 rounded"
              style={{
                borderTop: "6px solid #D7B6F1",
                background: "#F5EDFC80",
              }}
            >
              <div className="py-1 mt-3">
                <img alt="required_img" src={Sourcing} />
              </div>
              <div className="py-4">
                <div
                  style={{
                    fontSize: "1.3rem",
                    color: "#151216s",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                >
                  24*7 <br />
                 Talent Sourcing
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div
              className="p-4 rounded my-3"
              style={{
                borderTop: "6px solid #D7B6F1",
                background: "#F5EDFC80",
              }}
            >
              <div className="py-1  mt-3 ">
                <img alt="required_img" src={DedicatedRecruiter} />
              </div>
              <div className="py-4">
                <h5
                  style={{
                    fontSize: "1.3rem",
                    color: "#151216",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                >
                  Dedicated <br />
                  Recruitment Team
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-3">
            <div
              className="p-4 rounded"
              style={{
                borderTop: "6px solid #D7B6F1",
                background: "#F5EDFC80",
              }}
            >
              <div className="py-1 mt-3">
                <img alt="required_img" src={ResearchSpecialist} />
              </div>
              <div className="py-4">
                <h5
                  style={{
                    fontSize: "1.3rem",
                    color: "#151216",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                >
                  Talent <br />
                  Research Specialist
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-4 my-3">
            <div
              className="p-4 rounded"
              style={{
                borderTop: "6px solid #D7B6F1",
                background: "#F5EDFC80",
              }}
            >
              <div className="py-1 mt-3">
                <img alt="required_img" src={ResearchSpecialist} />
              </div>
              <div className="py-4">
                <h5
                  style={{
                    fontSize: "1.3rem",
                    color: "#151216",
                    fontWeight: "500",
                    lineHeight: "28px",
                  }}
                >
                  Dedicated <br />
                  Account Management
                </h5>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="container row d-flex justify-content-center"
            align="center"
          >
            <div className="col-md-6 mt-3">
              <div className="p-md-4 mb-3">
                <div
                  className="p-3 rounded"
                  style={{
                    borderTop: "6px solid #D7B6F1",
                    background: "#F5EDFC80",
                  }}
                >
                  <div className="py-1 mt-3">
                    <img alt="required_img" src={Endtoend} />
                  </div>
                  <div className="py-1">
                    <div
                      style={{
                        fontSize: "1.1rem",
                        color: "#151216s",
                        fontWeight: "500",
                        lineHeight: "28px",
                      }}
                    >
                      End-to-End to  <br />Recruitment Management
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>

            <div className="col-md-6 mt-3">
              <div className="p-md-4 mb-3">
                <div
                  className="p-3 rounded"
                  style={{
                    borderTop: "6px solid #D7B6F1",
                    background: "#F5EDFC80",
                  }}
                >
                  <div className="py-1 mt-3">
                    <img alt="required_img" src={Ondemand} />
                  </div>
                  <div className="py-1">
                    <div
                      style={{
                        fontSize: "1.3rem",
                        color: "#151216s",
                        fontWeight: "500",
                        lineHeight: "28px",
                      }}
                    >
                      OnDemand <br />Recruitment services
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="col-md-6 mt-3                                                                                                                                  ">
              <div className="p-md-4 mb-3">
                <div
                  className="p-3 rounded"
                  style={{
                    borderTop: "6px solid #D7B6F1",
                    background: "#F5EDFC80",
                  }}
                >
                  <div className="py-1 mt-3">
                    <img alt="required_img" src={Sourcing} />
                  </div>
                  <div className="py-1">
                    <div
                      style={{
                        fontSize: "1.3rem",
                        color: "#151216s",
                        fontWeight: "500",
                        lineHeight: "28px",
                      }}
                    >
                      24*7 <br />Talent Sourcing
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="col-md-6 mt-3                                                                                                                                  ">
              <div className="p-md-4 mb-3">
                <div
                  className="p-3 rounded"
                  style={{
                    borderTop: "6px solid #D7B6F1",
                    background: "#F5EDFC80",
                  }}
                >
                  <div className="py-1 mt-3">
                    <img alt="required_img" src={DedicatedRecruiter} />
                  </div>
                  <div className="py-1">
                    <div
                      style={{
                        fontSize: "1.3rem",
                        color: "#151216s",
                        fontWeight: "500",
                        lineHeight: "28px",
                      }}
                    >
                     Dedicated <br /> Recruitment Team
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="col-md-6 mt-3                                                                                                                                  ">
              <div className="p-md-4 mb-3">
                <div
                  className="p-3 rounded"
                  style={{
                    borderTop: "6px solid #D7B6F1",
                    background: "#F5EDFC80",
                  }}
                >
                  <div className="py-1 mt-3">
                    <img alt="required_img" src={ResearchSpecialist} />
                  </div>
                  <div className="py-2">
                    <div
                      style={{
                        fontSize: "1.3rem",
                        color: "#151216s",
                        fontWeight: "500",
                        lineHeight: "28px",
                      }}
                    >
                      Talent Research Specialist
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="col-md-6 mt-3                                                                                                                                  ">
              <div className="p-md-4 ">
                <div
                  className="p-3 rounded"
                  style={{
                    borderTop: "6px solid #D7B6F1",
                    background: "#F5EDFC80",
                  }}
                >
                  <div className="py-1 mt-3">
                    <img alt="required_img" src={ResearchSpecialist} />
                  </div>
                  <div className="py-2">
                    <div
                      style={{
                        fontSize: "1.3rem",
                        color: "#151216s",
                        fontWeight: "500",
                        lineHeight: "28px",
                      }}
                    >
                      Dedicated Account Management
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
