import React from "react";
import Herosection from "../Components/Herosection";
import PartnersSection from "../Components/PartnersSection";
import Services from "../Components/OurServices";
import Whoweare from "./Whoweare";
// import Faq from "./Faq";
// import Located from "./Located";
// import Navbar from "./Navbar";
import OurOfferings from "./OurOfferings";
// import OurProcess from "./OurProcess";
import ReachOut from "./ReachOut";
// import ServiceProvider from "./ServiceProvider";
import WebsiteFooter from "./WebsiteFooter";
import GradientVideo from "./GradientVideo";
import Disruptors from "./Disruptors";
import EngageUs from "./Engage";
import Tools from "./Tools";
// import CookieConsent, {Cookies} from "react-cookie-consent";
export default function Homepage() {
  // console.log(Cookies,"cookie");
  return (
    <div className="sections">
      <span className="container">
        {" "}
        {/* <CookieConsent
          className="container"
          enableDeclineButton
      
          acceptOnOverlayClick={false}
        
          hideOnAccept={true}
          debug={true}
          declineButtonStyle={{
            textDecoration: "underline",
            color: "#9D4EDD",
            background: "none",
          }}
          declineButtonText="Close"
          style={{
            backgroundColor: "white",
            color: "black",
            fontSize: "13px",
            transition: "transform 3s ease-in",
            transform:"translateY(0%)"
          }}
          buttonStyle={{ background: "#9D4EDD", color: "white" }}
          buttonText="Accept All Cookies"
          align="center"
        >
          By clicking “Accept All Cookies”, you agree to the storing of cookies
          on your device to enhance site navigation, analyze site usage, and
          assist in our marketing efforts.
        </CookieConsent> */}
      </span>
      <Herosection />
      <Whoweare />
      <Services />
      {/* <ServiceProvider /> */}
      <Disruptors />
      {/* <OurProcess /> */}
      <EngageUs />
      <Tools />
      <OurOfferings /> <PartnersSection />
      {/* <Faq color={"bg-white text-dark"}/> */}
      <ReachOut />
      <GradientVideo />
      {/* <Located /> */}
      <WebsiteFooter />
      <div className="scroll-container">
        <a className="w-11 h-11"
          href="#top"
          style={{
            padding: "0.8rem 1rem",
            // fontSize: '20px',

            backgroundColor: "#9D4EDD",
            color: "#fff",
            textAlign: "center",
            borderRadius: "100%",
            border: "none",
          }}
        >
          {" "}
          <i className="fas fa-angle-up"></i>
        </a>
      </div>
      {/* <button
        type="button"
        data-dial-toggle="speed-dial-menu-default"
        aria-controls="speed-dial-menu-default"
        aria-expanded="false"
        className="flex justify-center items-center w-11 h-11 up text-white bg-blue-700 rounded-full hover:bg-blue-800 focus:ring-2 focus:ring-blue-300 focus:outline-none"
      >
        <svg
          className="group-hover:animate-bounce w-6 h-6 up_icon"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 11l7-7 7 7M5 19l7-7 7 7"
          ></path>
        </svg>
        <span class="sr-only">UP</span>
      </button> */}
    </div>
  );
}
