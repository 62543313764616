import React from "react";
import Navbar from "./Navbar";
import WebsiteFooter from "./WebsiteFooter";

function Terms() {
  return (
    <div>
      <div style={{ backgroundColor: "#F5EDFC" }}>
        <Navbar />
        <div className="container mt-5 py-5">
          <div
            className=" mt-5 p-5"
            style={{
              fontSize: "2.9rem",
              fontWeight: "800",
              lineHeight: "52px",
            }}
            align="center"
          >
            Terms & Conditions
          </div>{" "}
        </div>
      </div>
      <div className="container mt-5 py-5">
        <div>
          <div>
            Welcome to The Talent RPO’s website. The terms and conditions of
            use, govern our relationship with you in connection to this website.
            If you use or browse this website in any way, you consent to comply
            with and be bound by them. You are the user or viewer of our
            website, and the terms company or us or we relate to the TalentRPO
            website's owner. The following terms of use govern the use of this
            website:
          </div>
          <div className="mt-3">
            {" "}
            <b>Disclaimer:</b>
            <div className="ps-5 mt-3">
              {" "}
              ● Links to external websites may be found on this website. When
              you click on such links, the external website may open in a
              full-screen mode (in which case you must use your browser's back
              button to return to this website), or it may appear within a frame
              on this website in some situations (in which case you will be able
              to return to this web site by using the navigation buttons within
              the frame). When an external website is framed within our website,
              it is solely for convenience in returning to this website and does
              not imply that we are responsible for the external website in
              question. These links are offered to make it simple and quick for
              you to access relevant websites, services, and/or goods that may
              be of interest to you. You must determine if any services and/or
              goods you see on any of these websites are appropriate for your
              needs. No conditions, warranties, or other terms or
              representations are made or entered into by TalentRPO with respect
              to the owners or operators of these websites, the goods or
              services they provide, or the content of their websites, and no
              liability is accepted with respect to any of these.
            </div>
            <div className="ps-5 mt-3">
              {" "}
              ● You should not rely on any of the information or guidance
              offered as part of this website since it is all intended to be
              generic in nature. Although TalentRPO makes every effort to ensure
              that the information supplied as part of this website is accurate
              at the time of inclusion on the website, it does not make any
              guarantees regarding the correctness of such material. As a result
              of your reliance on this information or advice, TalentRPO is not
              responsible for any actions you may take or for any losses or
              damages you may experience as a result.
            </div>
            <div className="ps-5 mt-3">
              {" "}
              ● TalentRPO retains the right to examine any data sent or received
              through any offered forum. TalentRPO reserves the right to at any
              time examine, remove, or otherwise block any posted material at
              its sole discretion and without prior notice.
            </div>
            <div className="ps-5 mt-3">
              {" "}
              ● Please be advised that any communications posted on the site
              represent the views of the individual who submitted such
              communication and are not to be considered as the views of
              TalentRPO should any portion of this website give you the chance
              to participate in or read from a topic or blog. You must not use
              the forum to publish, upload, or otherwise send information or
              images that are defamatory, violate personal privacy, or are
              otherwise illegal. We accept no responsibility or liability for
              anything placed on the forum by any user.
            </div>
          </div>
        </div>
        <div className="mt-3">
          <b>Terms and Conditions:</b><br />
          This website contains information that is covered by copyright and/or
          other intellectual property rights. This content includes text,
          pictures, other images, and sound. All copyrights and other
          intellectual property rights in this content are either owned by
          TalentRPO, have been granted a licence by the owner(s) of those
          rights, or have been expressly authorised by the owner(s) to be used
          as part of this website's content. All trademarks used without the
          company's permission or ownership that are copied on this website are
          acknowledged on the page.
        </div>
        <div>
          There are trademarks on this website as well, including the name
          "TalentRPO." All trademarks displayed on this website are the property
          of TalentRPO or are used with permission from the respective owner(s)
          of those trademarks.
        </div>
        <div className="mt-3">
          <b>You may:</b>
          <div className="ps-5">
            ● Access any page of the website
            <br />● Access any page of the website
          </div>
        </div>
        <div className="mt-3">
          <b>You may not:</b>
          <div className="ps-5">
            ● Any material contained on the website may not be copied,
            distributed (including making copies), broadcast, stored on disc,
            downloaded, altered, subjected to any kind of tampering, or used in
            any other manner than as permitted under "You may." These
            limitations apply to all or a portion of the website's content.
            <br />● Any material copied or printed off from the website should
            not have any copyright, trade mark, or other intellectual property
            notices present.
            <br />● without having received our prior written consent, link to
            this website.
          </div>
        </div>
        <div className="mt-3">
          <div>
            <b>
              If you need to provide a hypertext or other link to this website,
              please email with details of:
            </b>
            <div className="ps-5">
              {" "}
              ● The website(s) from which you intend to link to this one,
              including their URL(s)
              <br />● The URL(s) of the web page(s) on this website that you
              want to link, and we'll take your request into consideration. We
              are free to say "no" or "yes" to your request; it is entirely up
              to us.
            </div>
          </div>
        </div>
        <div className="mt-3">
          <b>Changes to Terms and Conditions:</b>
          <div>
            The terms and conditions and disclaimer outlined above are subject
            to change at any time by TalenRPO. You should review the terms and
            conditions and disclaimer each time you visit this website since by
            using it, you agree to be bound by them.
          </div>
        </div>
        <div className="mt-3">
          <b>Changes to/Operation of Website:</b>
          <div className="ps-5">
            ● This website's structure and content are subject to modification
            at any time by TalentRPO.
            <br />● For support or maintenance purposes, content updates, or for
            any other reason, TalentRPO may suspend the functioning of this
            website.
            <br />● Access to this website may be terminated at any moment and
            without prior notice by TalentRPO.
          </div>
        </div>
        <div className="mt-3">
          <b>Data Protection:</b>
          <div>
            If you supply TalentRPO or one of its connected firms with personal
            information through this website, that information will only be used
            in compliance with our privacy policy. Before moving on, please
            carefully read this. You agree to the use of your personal
            information in accordance with our privacy policy by giving us your
            personal information.
          </div>
        </div>
        <div className="mt-3">
          <b>Jurisdiction:</b>
          <div>
            The laws of the United States of America shall govern the
            interpretation and application of these terms and conditions, and in
            the event of any dispute arising out of or relating to these terms
            and conditions or the website, whether in contract, tort, or
            otherwise, the US courts shall have non-exclusive jurisdiction over
            such dispute.
          </div>
        </div>
      </div>

      <WebsiteFooter />
    </div>
  );
}
export default Terms;
