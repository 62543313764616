import React from "react";
import right_curve from "../Images/right_curve.png";
function Career() {
  return (
    <div>
      <div className="container p-5">
        <div
          className=" p-3"
          align="center"
          style={{ background: "#9D4EDD", borderRadius: "20px" }}
        >
          {/* <img src={right_curve}></img> */}

          <div
            className="text-align-center py-4"
            style={{
              color: "white",
              fontSize: "57px",
              fontWeight: "700",
              lineHeight: "68px",
            }}
          >
            Ready to have a great
            <br />
            <span style={{ background: "" }}>Career a head?</span>
          </div>
          <div
            className="btn "
            style={{
              border: "1px solid #F5EDFC",
              color: "white",
              padding: "16px 48px 16px 48px",
              fontWeight: "700",
            }}
          >
            Call Now
          </div>
        </div>
      </div>
    </div>
  );
}
export default Career;
